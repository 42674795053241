.block-text-image
  display: flex
  //width: 100%
  padding: 60px 0 30px
  +mq(tablet)
    flex-direction: column
    padding: 40px 0 20px
  +mq(mobile)
    padding: 20px 0

  &__image
    min-height: 400px
    width: 50%
    background-position: center
    background-size: cover
    +mq(tablet)
      min-height: 300px
      width: 100%
    +mq(mobile)
      min-height: 250px

    img
      display: block
      width: 100%

  &__content 
    width: 50%
    +mq(tablet)
      width: 100%
    +mq(mobile)
      padding-bottom: 20px
    
    .wrapper &
      width: 100%

    .half-wrapper
      padding-left: 30px
      +mq(tablet)
        padding: 30px 10px 0
        max-width: 100%

    .section-title
      color: $primary-color

    .button
      margin-top: 40px
      +mq(mobile)
        margin: 15px auto

    &--left
      display: flex
      justify-content: flex-end

      .half-wrapper
        padding-right: 30px
        padding-left: 0
        +mq(tablet)
          padding: 30px 10px 0
