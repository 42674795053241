[data-extranet-form] 
    display: none
    
.block__medium-color
    background-color: $medium-color

.page__extranet
    .footer
        padding-top: 0
    .page__content
        padding-bottom: 70px
    &--small
        .page__content
            padding-bottom: 0

.extranet-page
    &__title
        padding: 55px 0 65px
        line-height: 1
        color: $primary-color
        @extend .block__medium-color
        +mq(tablet)
            padding: 40px 45px
        +mq(mobile)
            padding: 35px 40px
        &--margin
            margin-bottom: 55px

.private
    span
        display: flex
        align-items: center
        &:after
            content: ''
            flex: 0 0 auto
            display: block
            margin-left: 20px
            width: 19px
            height: 25px
            +backgroundImage('lock.png', 100%, center)
    &--reversed
        span:after
            order: -1
            margin-left: 0
            margin-right: 10px

.triangle
    position: relative
    &:after
            content: ''
            display: block
            width: 0
            height: 0
            position: absolute
            transform: translateY(-50%)
            right: 0
            top: 50%
            border-style: solid
            border-width: 11.5px 0 11.5px 19px
            border-color: transparent transparent transparent $light-color
            transition: 0.2s
    &--blue
        &:after
            border-color: transparent transparent transparent $accent-color-3

[data-accordion-trigger]
    cursor: pointer

.no-desktop
    display: none
    +mq(tablet)
        display: block
        &--stat
            display: flex
            align-items: center
            svg
                margin-left: 0
                margin-right: 10px
                width: 21px
                height: 17px
                transform: rotate(-90deg)


// @media screen and ( max-width:  420px)
//     .header__logo__subtitle__button
//         display: none