.block-link
  padding: 120px 0 30px
  +mq(tablet)
    padding-top: 80px
  +mq(mobile)
    padding-top: 40px

  .section-title
    text-align: center
  
  &__nav
    display: flex
    justify-content: center
    margin: 0 -10px
    padding-top: 40px
    +mq(tablet)
      margin: 0
      flex-wrap: wrap

  &__item
    max-width: 360px
    width: 100%
    margin: 0 10px
    +mq(tablet)
      margin: 10px
    +mq(mobile)
      margin: 10px 0

    &:hover
      text-decoration: none

      .block-link__item__thumb
        opacity: .7

    &__thumb
      padding-top: 63.88%
      background-size: cover
      background-position: center
      transition: opacity 200ms ease

    &__title
      max-width: 90%
      padding-top: 15px
      text-transform: uppercase
      font-weight: $semi-bold
      +mq(mobile)
        +font-size(14px)