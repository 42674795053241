$fontsPath: "/web/fonts"

@font-face
  font-family: "Open Sans"
  src: url($fontsPath + "/OpenSans-LightItalic.woff2") format("woff2"),url($fontsPath + "/OpenSans-LightItalic.woff") format("woff")
  font-weight: 300
  font-style: italic

@font-face
  font-family: "Open Sans"
  src: url($fontsPath + "/OpenSans-Regular.woff2") format("woff2"),url($fontsPath + "/OpenSans-Regular.woff") format("woff")
  font-weight: normal
  font-style: normal

// @font-face
//   font-family: "OpenSans"
//   src: url($fontsPath + "/OpenSans-Italic.woff2") format("woff2"),url($fontsPath + "/OpenSans-Italic.woff") format("woff")
//   font-weight: normal
//   font-style: italic

@font-face
  font-family: "Open Sans"
  src: url($fontsPath + "/OpenSans-Bold.woff2") format("woff2"),url($fontsPath + "/OpenSans-Bold.woff") format("woff")
  font-weight: bold
  font-style: normal

@font-face
  font-family: "Open Sans"
  src: url($fontsPath + "/OpenSans-SemiBold.woff2") format("woff2"),url($fontsPath + "/OpenSans-SemiBold.woff") format("woff")
  font-weight: 600
  font-style: normal

@font-face
  font-family: "Open Sans"
  src: url($fontsPath + "/OpenSans-SemiBoldItalic.woff2") format("woff2"),url($fontsPath + "/OpenSans-SemiBoldItalic.woff") format("woff")
  font-weight: 600
  font-style: italic
