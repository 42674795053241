$input-height: 38px

[data-page="extra_faq"]  .modal__close
    display: none

.faq-page
    &__inner
        display: flex
        align-items: center
        justify-content: center
        +mq(tablet)
            align-items: start
        +mq(mobile)
            flex-direction: column

    &__column
        display: flex
        align-items: center
        +mq(tablet)
            flex-direction: column
            align-items: start
    &__title
        color: $accent-color-3
        width: 270px

    &__button
        margin-left: 4.017857% // 45px/1120px */
        width: 250px
        +mq(tablet)
            margin-left: 0
            margin-top: 30px

    &__form
        margin-left: auto
        position: relative
        +mq(mobile)
            margin-left: 0
            margin-top: 10px
            margin-bottom: 10px
            width: 100%
        form
            display: flex
            width: 100%
            align-items: center
            +mq(tablet)
                justify-content: center

        input[type="search"]
            background-color: $light-color

        input[type="submit"]
            background-color: $accent-color-3
            color: $light-color

        input[type="search"]
            max-width: 325px
            width: 100%
            flex-grow: 1
            height: 38px
            border: 1px solid #e7e7e7
            padding: 0 10px
            +font-size(12px)
            +placeholder(#bec0c1, #bec0c1, italic)
            +mq(mobile)
            max-width: none

        input[type="submit"]
            display: block
            height: calc( #{$input-height} + 2px)
            width: 48px
            font-weight: 600
            cursor: pointer
            +font-size(14px)

        &__reset
            display: flex
            align-items: center
            margin-left: 20px
            font-weight: $bold
            position: absolute
            text-transform: none
            top: calc(#{$input-height} + 15px)
            right: 0
            color: $accent-color-3
            +font-size(12px)
            +mq(mobile)
                margin-left: 0
                margin-top: 15px

            svg
                width: 1.4em
                height: 1.4em
                fill: $accent-color-3
                margin-left: 8px

    &__actions
        padding: 55px 0
        &__inner
            display: flex
            align-items: center
            justify-content: center
            padding: 0 3%
            +mq(mobile)
                flex-wrap: wrap
                padding: 0

        .button
            width: 48.660714% /* 545px/1120px */
            min-width: 300px
            height: 110px
            flex: 0 0 auto
            +mq(mobile)
                width: 100%
                height: 90px
                padding-right: 20px
                padding-left: 20px

            &:first-child
                line-height: 110px
                +mq(mobile)
                    line-height: 90px

            &:not(:first-child)
                margin-left: 45px
                +mq(mobile)
                    margin-left: 0
                    margin-top: 30px

            &--question::before
                left: 12%
                +mq(tablet)
                    left: 8%
        &__doc
            display: flex
            text-align: center
            align-items: center
            &::before
                content: ''
                height: 50px
                width: 42px
                position: relative
                left: 1%
                flex: 0 0 auto
                +backgroundImage('icon-document.png', cover, center)
                +mq(tablet)
                    left: 0

.faq
    .is-open
        .triangle:after
            transform: translateY(-50%) rotate(90deg)

    &__category
        background-color: $accent-color-3
        color: $light-color
        padding: 35px 0
        +font-size(18px)
        +mq(tablet)
            padding: 25px 0
            +font-size(16px)
        +mq(mobile)
            +font-size(14px)
        &--followed
            background-color: #f0f0f0
            color: $accent-color-3
            span
                display: flex
                align-items: center
                svg
                    width: 27px
                    height: 39px
                    stroke: $accent-color-3
                    fill: $light-color
                    margin-left: 30px
    &__item
        padding: 50px 30px
        +font-size(14px)
        &:not(:last-child)
            border-bottom: 1px solid $accent-color-3
        +mq(mobile)
            padding: 30px 20px

        &__inner
            display: flex

        &__column
            max-width: 960px
            padding-right: 20px
            width: 85.714286% /* 960px/1120px */

        &__content
            line-height: 1.2
            margin-bottom: 50px
            +font-size(18px)
            +mq(tablet)
                +font-size(17px)
            +mq(mobile)
                margin-bottom: 30px
                +font-size(15px)

        &__infos
            font-style: italic
            margin-bottom: 10px

        &__status
            text-decoration: underline
            font-weight: 600

    &__answer
        background-color: #f0f0f0
        padding: 25px 5.803571% 50px
        color: $accent-color-3
        margin-top: 25px

        &__admin
            display: flex
            align-items: center
            font-weight: 600
            margin-bottom: 25px
            +font-size(14px)
            &::before
                content: ''
                margin-right: 14px
                width: 36px
                padding-bottom: 36px
                +backgroundImage('icon-profil.png', cover, center)

        &__content
            line-height: 1.2
            margin-bottom: 25px
            +font-size(16px)

        &__data
            text-align: right
            font-style: italic
            +font-size(14px)

    &__btrack
        margin-left: auto
        text-transform: uppercase
        color: $accent-color-3
        text-align: right
        svg
            height: 53px
            width: 38px
            margin-bottom: 5px
            display: block
            margin-left: auto

        &--unfollow svg
            fill: $accent-color-3
            stroke: transparent

        &--follow
            stroke: $accent-color-3
            fill: transparent

    &__no-result
        margin-top: 50px
        margin-bottom: 50px
        +font-size(16px)

.faq-ask
    display: flex
    position: relative

    +mq(mobile)
        flex-wrap: wrap

    &__close
        position: absolute
        top: 0
        right: 0
        padding: 20px 25px
        font-weight: bold
        display: flex
        align-items: center
        z-index: 1
        cursor: pointer
        +mq(mobile)
            padding-top: 17px
            padding-bottom: 17px
        svg
            fill: white
            height: 12px
            width: 12px
            margin-left: 4px

    &__column
        padding: 100px 0 30px
        position: relative
        +mq(mobile)
            width: 100% !important
            padding: 60px 0 20px
    &__title
        position: absolute
        background-color: $accent-color-3
        top: 0
        left: 0
        right: 0
        padding: 13px 25px
        text-transform: uppercase
        color: $light-color
        font-weight: bold
        display: flex
        align-items: center
        +font-size(16px)
        +mq(tablet)
            +font-size(14px)
        +mq(mobile)
            padding-top: 15px
            padding-bottom: 15px
            +font-size(12px)

        &::before
            content: ''
            display: block
            width: 12px
            height: 20px
            margin-right: 12px
            +backgroundImage('question-mark.png', cover, center)

    &__infos
        width: 38.596491% /* 330px/855px */
        padding-left: 20px
        padding-right: 20px
        background-color: $light-color

        &__content
            color: $primary-color
            line-height: 1.285
            +font-size(14px)

    &__form
        flex: 1
        padding-left: 35px
        padding-right: 35px
        background-color: $medium-color
        +mq(mobile)
            padding-left: 20px
            padding-right: 20px
            padding-top: 30px

        &__row:not(:first-child)
            margin-top: 30px

        .triangle:after
            right: 25px
            transform: translateY(-50%) rotate(90deg) scale(0.8)

        select
            width: 100%
            padding: 18px 20px
            background-color: $accent-color-3
            border-radius: 0
            border: 0
            font: inherit
            line-height: 1
            color: $light-color
            -moz-appearance: none
            text-transform: uppercase
            -webkit-appearance: none
            font-weight: bold
            +font-size(14px)

            &:focus,
            &:active
                color: $light-color !important
                background-color: $accent-color-3

            &:-moz-focusring
                color: $light-color
                text-shadow: 0 0 0 #000
                background-color: $accent-color-3

            option
                background-color: $light-color
                color: $accent-color-3

        &__textarea
            label
                cursor: pointer
                display: block
                background-color: $accent-color-3
                text-transform: uppercase
                font-weight: bold
                color: $light-color
                line-height: 1
                padding: 18px 20px
                +font-size(14px)

            textarea
                display: block
                resize: none
                width: 100%
                min-height: 130px
                padding: 30px 25px
                border: 1px solid #e0e3e2
                font-family: $body-font
                +font-size(14px)
                +placeholder(#939593)

        &__required
            color: #939593
            text-align: right
            padding-right: 20px
            margin-bottom: 30px
            +font-size(11px)

        &__button
            font-weight: 600
            text-align: right
            .button
                display: inline-block
