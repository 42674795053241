.search

  &__form
    padding: 60px 0 120px
    background-color: $primary-color
    +mq(tablet)
      padding: 40px 0 80px

    &__inner
      max-width: 370px
      width: 100%
      margin: 0 auto
      padding: 0 10px

    &__heading
      text-align: center
      font-weight: $semi-bold
      font-style: italic
      color: $light-color
      +font-size(22px)
      +mq(tablet)
        +font-size(18px)

    form
      display: flex
      padding-top: 30px
      +mq(tablet)
        padding-top: 15px

    input[type="search"]
      max-width: 325px
      width: 100%
      flex-grow: 1
      height: 40px
      padding: 0 20px
      background-color: $light-color
      +font-size(12px)
      +placeholder(#8e8e8e)

    input[type="submit"]
      display: block
      height: 40px
      width: 40px
      background-color: #f6f6f6
      color: $accent-color-3
      font-weight: bold
      cursor: pointer

  &__wrapper
    background-color: $medium-color

  &__empty
    max-width: 610px
    padding-top: 50px
    padding-bottom: 50px
    color: $primary-color
    font-weight: $semi-bold
    font-style: italic
    text-align: center
    +font-size(20px)

  &__header
    padding: 30px 0
    +mq(tablet)
      padding: 15px 0

  &__number
    font-weight: $semi-bold
    font-style: italic
    color: $primary-color
    +font-size(20px)
    +mq(tablet)
      +font-size(14px)

  &__tabs
    padding-top: 30px
    +mq(tablet)
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      padding-top: 0

    button
      padding: 10px 25px
      margin-right: 60px
      border-bottom: 1px solid $primary-color
      +font-size(12px)
      +mq(tablet)
        max-width: 48%
        width: 100%
        margin-right: 0
        margin-top: 10px

      &.is-active
        font-weight: $bold
        border-bottom: 4px solid $primary-color

  &__results

    &__panel
      display: none
      padding-bottom: 40px

      &.is-active
        display: block

      .button
        margin: 40px auto 0

    &__locations
      display: flex
      flex-wrap: wrap
      margin: 0 -10px

    &__documents
      display: flex
      flex-wrap: wrap
      max-width: 950px

  &__main
    position: relative

  &__loader
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    background-color: rgba(#000, .6)
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
    visibility: hidden
    pointer-events: none
    opacity: 0
    transition: 400ms $ease-out
    z-index: z(nav)
    .is-loading &
      visibility: visible
      pointer-events: all
      opacity: 1

    &__inner
      display: flex
      justify-content: center
      align-items: center
      width: 80px
      height: 80px
      background-color: $light-color
      transform: translate3d(0, 100px, 0)
      opacity: 0
      transition: 400ms $ease-out
      .is-loading &
        opacity: 1
        transform: none

      img
        animation: spin 800ms linear infinite
        width: 35px
        height: 35px

  &__result
    padding: 30px
    border-bottom: 1px solid #000
    opacity: 0
    animation: slide-up 250ms ease-out forwards

    &:last-child
      border: none

    &__title
      font-weight: $semi-bold
      +font-size(16px)

    &__content
      padding-top: 15px
      +font-size(12px)
