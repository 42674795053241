$assetPath: '/web/'
$assetPathImg: $assetPath + 'img/'

$light-color: #fff !default
$dark-color: #050505 !default
$primary-color: #315562 !default
$medium-color: #f1f3f2 !default
$medium-dark-color: #bdc8cd !default
$primary-dark-color: #17272d !default
$accent-color-1: #95b700 !default
$accent-color-2: #a5016f !default
$accent-color-3: #007d9d !default

$student-color: $accent-color-1 !default
$center-color: $accent-color-2 !default
$partner-color: $accent-color-3 !default

$error-form-color: rgba(255,0,0,0.75) !default

$light: 300 !default
$regular: regular !default
$semi-bold: 600 !default
$bold: bold !default

$ease-out: cubic-bezier(0.175, 0.885, 0.32, 1)

$body-font: 'Open Sans', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default

$z-indexes: (base: 0, content: 2, menu: 4, nav: 6, menu-actions: 8, overlay: 10, cookies: 12 ) !default
$breakpoints: (tablet: 'screen and ( max-width: 980px )', mobile: 'screen and (max-width: 700px )') !default
