.social-nav
  display: flex

  &__item
    display: flex
    justify-content: center
    align-items: center
    height: 32px
    width: 32px
    margin: 0 5px
    border-radius: 16px
    border: 2px solid $light-color
    +mq(tablet)
      height: 48px
      width: 48px
      border-radius: 24px

    svg
      height: 12px
      width: 12px
      fill: $light-color
      +mq(tablet)
        height: 18px
        width: 18px