.account
    &__block
        margin-bottom: 70px
        &--longer
            margin-bottom: 80px
            +mq(tablet)
                margin-bottom: 60px

        .section-title
            color: $primary-color

        &__title
            margin-bottom: 35px

        &__main
            position: relative
            padding-left: 40px
            padding-right: 40px
            @extend .block__medium-color
            +mq(tablet)
                padding-left: 20px
                padding-right: 20px
            +mq(mobile)
                padding-left: 15px
                padding-right: 15px

            &--title
                padding-top: 40px
                padding-bottom: 70px
                // display: flex
                // align-items: center
                // margin-bottom: 45px
                +mq(tablet)
                    padding-top: 30px
                    padding-bottom: 60px

                &--disabled
                    &.section-title
                        color: #6a6a6a
                        span:before
                            background-color: #6a6a6a

            &__title
                display: flex
                align-items: center
                margin-bottom: 45px
                +mq(mobile)
                    margin-bottom: 25px

            &--no-title
                padding-top: 75px
                padding-bottom: 150px
                +mq(tablet)
                    padding-top: 40px
                    padding-bottom: 60px

        &__columns
            display: flex
            justify-content: space-between
            +mq(tablet)
                flex-wrap: wrap
            form
                width: 48.214286% /* 540px/1120px */
                +mq(tablet)
                    width: 100%
                    &:not(:last-child)
                        margin-bottom: 60px

    &__infos
        display: flex
        font-weight: bold
        color: $primary-color
        justify-content: space-between
        +font-size(18px)
        +mq(tablet)
            flex-wrap: wrap
            +font-size(16px)
        +mq(mobile)
            +font-size(15px)

        &__column
            +mq(tablet)
                width: 100% !important
            &--small
                width: 43.26923077% //450/1040
            &--med
                width: 46.15384615% //480/1040
            &--large
                width: 50% //520/1040
            &--disabled,
            &--disabled input
                color: #6a6a6a
        &__line
            display: flex
            +mq(tablet)
                margin-bottom: 17px
            &:not(:last-child)
                margin-bottom: 17px

            &--center
                align-items: center

            &--disabled
                color: #6a6a6a

        &__label
            width: 160px
            flex: 0 0 auto
            +mq(tablet)
                width: 25% !important
            +mq(mobile)
                width: 40% !important
            &--med
                width: 150px
            &--small
                width: 140px

        &__input
            width: 100%
            ul
                display: none
            &--error
                ul
                    display: block
                input
                    border-color: $error-form-color

        &__value
            padding: 10px 0 //Same height as the input

    &__actions
        text-align: center
        position: absolute
        top: calc(100% - 20px)
        left: 0
        right: 0
        .button
            font-weight: 600
            display: inline-block
            +font-size(14px)
            &.active
                background-color: $accent-color-1

        &__cancel,
        &__edit
            cursor: pointer

        &__cancel
            text-decoration: underline
            color: $primary-color
            margin-top: 13px
            font-weight: 600
            display: block
            margin: 13px auto 0
            +font-size(14px)
            +mq(tablet)
                +font-size(13px)
            &:hover
                text-decoration: none

        .account__actions__cancel--edited,
        .account__actions__edited
            display: none

        &.account__actions--edited >
            .account__actions__cancel--edited
                display: block !important
            .account__actions__edited
                display: inline-block !important

            .account__actions__edit,
            .account__actions__cancel--edition
                display: none !important

    &__trigger
        display: none
        + .account__block__main
            .account__infos__value--dyn,
            .account__actions__edit
                display: inline-block
            .account__infos__input,
            .account__infos__line--edit,
            .account__actions__cancel--edition,
            .account__actions__submit
                display: none

        &:checked + .account__block__main
            .account__infos__value--dyn,
            .account__actions__edit
                display: none
            .account__infos__line input,
            .account__actions__cancel--edition,
            .account__infos__input
                display: block
            .account__infos__line--edit
                display: flex
            .account__actions__submit
                display: inline-block
