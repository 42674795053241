.find

  &__loader
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    background-color: rgba(#000, .6)
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
    visibility: hidden
    pointer-events: none
    opacity: 0
    transition: 400ms $ease-out
    z-index: z(nav)
    .is-loading &
      visibility: visible
      pointer-events: all
      opacity: 1

    &__inner
      display: flex
      justify-content: center
      align-items: center
      width: 80px
      height: 80px
      background-color: $light-color
      transform: translate3d(0, 100px, 0)
      opacity: 0
      transition: 400ms $ease-out
      .is-loading &
        opacity: 1
        transform: none

      img
        animation: spin 800ms linear infinite
        width: 35px
        height: 35px

  &__search
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    background-color: $primary-color
    padding: 15px 10px
    +mq(mobile)
      padding: 25px 10px

    &__title
      font-weight: $bold
      font-style: italic
      color: $light-color
      +font-size(22px)
      +mq(tablet)
        +font-size(18px)

    &__form
      display: flex
      align-items: center
      padding-left: 20px
      +mq(mobile)
        padding-top: 10px
        padding-left: 0
        width: 100%

      input
        flex-shrink: 0
        min-width: 320px
        padding: 15px
        background-color: $light-color
        color: $primary-color
        font: inherit
        font-weight: $semi-bold
        +font-size(12px)
        +mq(mobile)
          min-width: auto
          width: 100%
          padding: 10px 15px

  .button
    margin: 20px auto 0
    &[disabled]
      opacity: .5

  &__map
    position: relative
    background-color: beige
    overflow: hidden

    &__inner
      height: 520px
      z-index: 1

  &__popup
    max-width: 360px
    width: 100%
    position: absolute
    top: 50%
    left: 5%
    transform: translateY(-40%)
    opacity: 0
    pointer-events: none
    transition: 400ms $ease-out
    z-index: 2
    +mq(mobile)
      left: 0
      top: 0
      padding: 10px

    &.is-visible
      pointer-events: all
      opacity: 1
      transform: translateY(-50%)
      +mq(mobile)
        transform: none

    &__back
      padding: 5px 10px
      border-radius: 5px
      background-color: $primary-color
      color: $light-color
      font-weight: $semi-bold
      +font-size(12px)

    .result
      margin: 10px 0 0
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
      +mq(tablet)
        max-width: 360px
      +mq(mobile)
        opacity: .8
      &__section
        padding-left: 0

  &__filters
    height: 80px
    display: flex
    align-items: center
    +mq(mobile)
      padding: 15px 10px
      height: auto

    &__inner
      display: flex
      justify-content: space-between
      align-items: center
      flex-wrap: wrap
      +mq(mobile)
        justify-content: center

    &__main
      display: flex
      align-items: center
      flex-wrap: wrap

    &__reset
      display: flex
      align-items: center
      margin-left: 20px
      font-weight: $bold
      +font-size(12px)
      +mq(mobile)
        margin-left: 0
        margin-top: 15px

      svg
        width: 1.4em
        height: 1.4em
        fill: $primary-color
        margin-left: 8px

    &__results
      display: none
      text-transform: uppercase
      color: $primary-color
      font-weight: $semi-bold
      +font-size(12px)
      +mq(mobile)
        padding-top: 15px

  &__filter
    display: flex
    align-items: center

    &__label
      font-weight: $bold
      flex-shrink: 0
      padding-right: 10px
      +font-size(12px)

    select
      width: 100%
      min-width: 170px
      padding: 15px
      background-color: $primary-color
      border-radius: 0
      border: 0
      font: inherit
      line-height: 1
      color: $light-color
      -moz-appearance: none
      -webkit-appearance: none
      font-weight: $semi-bold
      +font-size(12px)

      &:focus,
      &:active
        color: $light-color !important

      &:-moz-focusring
        color: $light-color
        text-shadow: 0 0 0 #000

      &::-ms-expand
        display: none

      option
        background-color: $light-color
        color: $primary-color

  &__results
    position: relative
    padding: 40px 0
    background-color: $medium-color
    +mq(mobile)
      padding: 20px 0

    &__inner
      display: flex
      flex-wrap: wrap
      margin: 0 -10px

    &__search-success,
    &__empty
      width: 100%
      text-align: center
      padding: 0 10px 0 20px
      font-weight: $semi-bold
      color: $primary-color
      +font-size(14px)

.result
  display: flex
  flex-direction: column
  max-width: 360px
  width: 100%
  margin: 10px
  padding: 20px 24px
  background-color: $light-color
  font-weight: $semi-bold
  border-radius: 5px
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.006)

  @supports (display: grid)
    opacity: 0
    animation: slide-up 250ms ease-out forwards

  +mq(tablet)
    max-width: calc(50% - 20px)
  +mq(mobile)
    padding: 15px 20px
    max-width: 100%

  &__header
    display: flex
    align-items: center

  &__logo
    width: 30px

  &__badge
    margin: 0 8px
    padding: 5px
    border-radius: 5px
    font-weight: $semi-bold
    font-style: italic
    color: $light-color
    line-height: 1
    +font-size(12px)

    &--1
      background-color: $accent-color-2

    &--2
      background-color: #800080

    &--3
      background-color: $accent-color-3

    &--4
      background-color: #612661

  &__content
    flex-grow: 1

  &__section
    display: flex
    padding: 30px 0 0 60px
    +font-size(13px)
    +mq(mobile)
      padding: 15px 0 0

    svg
      width: 20px
      height: 20px
      fill: $primary-color
      flex-shrink: 0

    &__infos
      padding-left: 20px
      span
        text-transform: uppercase
        display: block

  &__name
    padding-top: 25px
    color: $primary-color
    font-weight: $bold
    text-align: center
    line-height: 1.2
    text-transform: uppercase
    +font-size(22px)
    +mq(tablet)
      +font-size(18px)
    +mq(mobile)
      padding-top: 15px
      +font-size(16px)

  &__telephone
    display: flex
    +font-size(13px)

    svg
      width: 16px
      fill: $primary-color

  &__organism
    +font-size(13px)
    span
      display: flex
      +font-size(16px)

    svg
      width: 20px
      fill: $primary-color

  &__footer
    display: flex
    justify-content: space-between
    +font-size(12px)
    text-decoration: underline
    padding-top: 40px
