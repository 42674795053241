.news
  padding: 35px 0 100px 0px

  .button
    background-color: $accent-color-3
    color: $light-color
    font-weight: $semi-bold

  &__inner
    display: flex
    align-items: flex-start
    padding-top: 35px
    +mq(tablet)
      flex-wrap: wrap
      justify-content: center

  &__image
    max-width: 545px
    width: 100%
    flex-shrink: 0
    +mq(tablet)
      margin-bottom: 10px
            
  &__main
    padding-left: 30px
    font-family: $body-font
    +mq(tablet)
      padding: 40px 0 0 0 

  &__title
    font-weight: $bold
    text-transform: uppercase
    color: $primary-color
    line-height: 26px
    +font-size(22px)
    +mq(tablet)
      text-align: center
    +mq(mobile)
      font-size: 18px

  &__date
    font-size: 12px
    font-weight: $semi-bold
    padding-top: 10px
    +mq(tablet)
      text-align: center

  &__content
    padding-top: 12px
    font-weight: $semi-bold
