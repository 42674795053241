.block-image
  &__desktop
    max-width: 100%
    width: 100%
    display: block
    +mq(tablet)
        display: none
  
  &__mobile
    max-width: 100%
    width: 100%
    display: none
    +mq(tablet)
        display: block
