.extranet__form
    &__input,
    &__textarea
        background: white
        color: $primary-color
        padding: 15px
        width: 100%
        border: 1px solid #dfe2e1
        line-height: 1
        font-weight: bold
        +font-size(18px)
        +placeholder(#979897)
        &--small
            padding: 11px
            +font-size(14px)
    &__error
        color: $error-form-color
        font-style: italic
        font-weight: bold
        padding-bottom: 5px
        +font-size(14px)
