.menu,
.menu__extranet
  position: absolute
  right: 0
  height: calc(100vh - 100px)
  width: 100%
  transition: 600ms $ease-out
  transform: translate3d(100%, 0, 0)
  color: $light-color
  z-index: z(menu)
  +mq(tablet)
    max-width: 100%
    height: auto
    flex: 1
    overflow-y: visible

  &.is-open
    transform: none

  &__overlay
    display: flex
    justify-content: flex-end
    height: 100%

  &__wrapper
    display: flex
    flex-direction: column
    max-width: 740px
    width: 100%
    background-color: $accent-color-3

  &__inner
    position: relative
    display: flex
    flex-direction: column
    flex: 1
    padding: 0 100px
    overflow-y: auto
    +mq(tablet)
      margin-bottom: 15px
      padding: 0 15px

  &__home
    position: absolute
    top: 0
    left: 0
    padding: 20px 30px
    color: $light-color
    text-transform: uppercase
    font-weight: 600
    background-color: $primary-color
    +font-size(12px)

    &:hover
      text-decoration: none

  &__links
    padding-top: 80px

  &__dropdown
    padding-bottom: 10px
    font-weight: $semi-bold

    &.is-visible
      .menu__dropdown__links
        display: block
      .menu__dropdown__trigger svg
        transform: rotate(-180deg)

    &__trigger
      display: flex
      align-items: center
      +font-size(22px)
      +mq(tablet)
        +font-size(18px)
      +mq(mobile)
        +font-size(16px)

      &:hover
        text-decoration: none

      svg
        margin-left: 10px
        width: 12px
        height: 12px
        fill: $light-color
        transition: transform 400ms $ease-out
        +mq(mobile)
          width: 8px
          height: 8px

    &__links
      padding: 10px 0 20px
      padding-left: 30px
      display: none

      a
        opacity: .4
        transform: opacity 400ms ease
        +font-size(18px)
        +mq(mobile)
          +font-size(16px)

        &:hover
          opacity: .8
          text-decoration: none

  &__footer
    padding: 80px 0
    margin-top: auto
    +mq(tablet)
      padding-bottom: 30px

    &__social
      display: flex
      align-items: center
      +mq(tablet)
        justify-content: center

      +mq(mobile)
        .social-nav__item
          width: 32px
          height: 32px

          svg
            width: 12px
            height: 12px

      > span
        margin-left: 10px
        font-style: italic
        font-weight: $semi-bold
        +mq(mobile)
          +font-size(14px)

    &__newsletter
      display: flex
      align-items: center
      margin-top: 20px
      font-style: italic
      font-weight: $semi-bold
      +mq(tablet)
        margin: 20px auto 0
      +mq(mobile)
        +font-size(14px)

      svg
        width: 24px
        height: 16px
        margin-right: 10px
        fill: $light-color

.menu--corporate
  +mq(tablet)
    position: static
    transform: none
    display: none

.menu__extranet

  &__wrapper
    position: relative
    background-color: $primary-color

  &__home
    background-color: $accent-color-3

  &__inner
    padding: 0 120px
    +mq(tablet)
      padding: 0 10%

  &__divide
    color: $light-color
    margin-bottom: 40px

  &__dropdown
    padding-bottom: 45px
    &__trigger
      line-height: 1

  &__footer
    +mq(tablet)
      padding-top: 40px

    &__contact
      display: flex
      align-items: center
      font-weight: 600
      margin-bottom: 25px
      +font-size(18px)

      svg
        width: 24px
        height: 16px
        margin-right: 20px
        fill: $light-color
