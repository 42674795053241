$padding-right-nav: 35px

.home-infos
    .home-split__half--left
        +mq(tablet)
            padding-left: 3%
            padding-right: 3%
        .half-wrapper
            justify-content: flex-start
            padding: 0 3%
            +mq(tablet)
                padding: 0
                max-width: none

    &__welcome
        +mq(tablet)
            order: -1
    +mq(tablet)
        order: -1

.news-extranet
    margin-top: 60px
    +mq(tablet)
        margin-top: 40px
    +mq(mobile)
        margin-top: 20px

.notifications
    &__title
        font-weight: bold
        color: $light-color
        text-transform: uppercase
        margin-bottom: 40px
        margin-top: 45px
        display: flex
        align-items: center
        +font-size(22px)
        +mq(tablet)
            margin-top: 10px
            margin-bottom: 25px
        svg
            margin-right: 20px
            height: 54px
            width: 75px
            +mq(tablet)
                width: 50px
                height: 36px

    &__list
        &__item
            display: flex
            padding-right: 11.7%
            &:not(:only-child)
                margin-bottom: 18px
            +mq(tablet)
                padding-right: 0
                justify-content: space-between
            &__text,
            &__link
                color: white
                font-weight: 600
                +font-size(14px)

            &__text
                width: 63.3%
                +mq(tablet)
                    padding-right: 3%

            &__link
                text-decoration: underline
                &:hover
                    text-decoration: none

.welcome
    padding-left: 80px
    margin-top: 60px
    margin-bottom: 50px
    display: block !important
    +mq(tablet)
        margin-top: 40px
        padding: 0

    &__title,
    &__text
        color: $primary-color
        +font-size(14px)
        em
            font-style: italic
    &__title
        font-weight: 600
        margin-bottom: 20px

    &__text
        margin-bottom: 20px

    &__button
        font-weight: 600

.faq-box
    background-color: $accent-color-3
    display: flex
    justify-content: center
    align-items: center

    &--home
        min-height: 400px
        +mq(tablet)
            min-height: auto
            padding: 20px 0 20px
        .faq-box__inner
            text-align: center

        .faq-box__title
            font-weight: 600
            font-style: italic
            margin-bottom: 33px
            +mq(tablet)
                margin-bottom: 15px

        .faq-box__img
            max-width: 211px
            width: 200%

    &--insert
        padding-top: 30px
        padding-bottom: 25px
        overflow: hidden //bfc
        .faq-box__img
            float: right
        .faq-box__title
            margin-bottom: 30px

    &__title
        color: white
        text-transform: uppercase
        +font-size(20px)
        &--big
            +font-size(22px)

    &__button
        font-weight: 600
        color: $accent-color-3
        @extend .button
        +mq(tablet)
            background-color: $light-color !important
            transform: none !important
    &__img
        +mq(tablet)
            margin-bottom: 15px

.extranet-slider
    &__title
        font-weight: bold
        text-transform: uppercase
        +font-size(22px)
        +mq(tablet)
            text-align: center
        &--top
            color: $accent-color-2
            margin-bottom: 20px
            transform: translateY(-90px)
            padding-left: 80px
            +mq(tablet)
            padding-left: 0
            transform: none
        &--in
            color: $light-color
            margin-bottom: 18px

    &__inner
        background-color: $accent-color-2
        padding: 80px 0 20px 0
        color: white
        overflow: hidden
        position: relative
        +mq(tablet)
            padding: 40px 0

    &__content
        color: white
        margin-bottom: 60px
        +font-size(14px)
        +mq(tablet)
            margin-bottom: 30px

    &__button
        margin-top: auto
        text-align: right
        .button
            display: inline-block

    &__item
        display: flex
        flex-direction: column
        padding: 0 $padding-right-nav 0 30px
        height: auto
        +mq(tablet)
            padding: 0 20px

    &__nav
        display: flex
        justify-content: flex-end
        position: absolute
        top: 30px
        right: $padding-right-nav
    +mq(tablet)
        display: none

    &__item
        display: flex
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        margin-left: 10px
        background-color: $light-color

        svg
            height: 16px
            width: 8px
            fill: $accent-color-2

    &__navigation
        display: none
        +mq(tablet)
            display: block

.home-find
    background-color: $accent-color-2
    margin-top: 60px
    +mq(mobile)
        text-align: center

    .section-title
        color: white

    .wrapper
        +backgroundImage('map.png', $size: auto, $position: right center)
        +mq(tablet)
            background-image: none

    &__title
        margin-bottom: 45px
        padding-top: 45px
        +mq(tablet)
            margin-bottom: 25px
            padding-top: 25px

    &__content
        display: flex
        align-content: center
        padding-bottom: 70px
        +mq(tablet)
            justify-content: space-between
        +mq(mobile)
            flex-direction: column
            padding-bottom: 40px
    &__else
        padding-right: 50px
        padding-left: 50px
        padding-top: 9px
        +mq(tablet)
            padding-right: 25px
            padding-left: 25px
        +mq(mobile)
            padding: 15px 0

    &__link
        font-weight: 600
        color: $accent-color-2
        +mq(mobile)
            display: block

    &__form
        min-width: 35.714286% /* 400px/1120px */
        +mq(tablet)
            width: 50%
        +mq(mobile)
            width: 100%

.home-documents
    margin-top: 75px
    &__title
        color: $primary-color

    &__inner
        margin-top: 40px

    &__footer
        text-align: center
        margin-top: 50px
        +mq(tablet)
            margin-top: 30px
        +mq(mobil)
            margin-top: 20px

        .button
            display: inline-block
            font-weight: 600
            padding: 15px 70px
            line-height: 1.857
            +mq(tablet)
                padding: 10px 40px
            +mq(mobile)
                padding-left: 15px
                padding-right: 15px
