.footer,
.footer__extranet
  padding-top: 40px
  color: $light-color
  +font-size(12px)
  +mq(tablet)
    padding-top: 0

  &__partners
    padding: 45px 0 30px
    background-color: $medium-color
    color: $dark-color
    +mq(tablet)
      padding: 30px 0

      .section-title
        text-align: center
        color: $primary-color

    &__slider
      display: flex
      align-items: center
      padding: 40px 40px 0
      +mq(tablet)
        padding: 30px 30px 0

    &__track
      max-width: 750px
      margin: 0 auto
      overflow: hidden

    &__nav
      display: flex
      align-items: center
      justify-content: center
      width: 30px
      height: 30px
      margin-left: 10px
      background-color: $primary-color
      +mq(tablet)
        display: none

      svg
        height: 16px
        width: 8px
        fill: $light-color

  &__partner
    display: flex
    justify-content: center
    align-items: center
    width: 170px
    height: 80px
    background-color: $light-color
    padding: 5px

    img
      max-height: 100%
      width: auto
      max-width: 160px

  &__newsletter
    display: flex
    justify-content: center
    align-items: center
    padding: 20px 0
    color: $light-color
    +mq(tablet)
      flex-direction: column

    &.is-valid
      .footer__newsletter__inner
        display: none
      .footer__newsletter__confirmation
        display: block

    &--1
      background-color: $accent-color-1

    &--2
      background-color: $accent-color-2

    &--3
      background-color: $accent-color-3

    &__title
      padding-right: 20px
      font-style: italic
      font-weight: $semi-bold
      +font-size(18px)
      +mq(mobile)
        +font-size(16px)

    &__inner
      display: flex
      align-items: center
      +mq(tablet)
        flex-direction: column

    &__confirmation
      display: none
      font-style: italic
      font-weight: $semi-bold
      +font-size(18px)
      +mq(mobile)
        +font-size(16px)

  &__main
    padding: 50px 0
    background-color: $primary-color
    +mq(tablet)
      display: none

    &__inner
      display: flex

    .column
      width: 100%

      &__title
        font-weight: $semi-bold
        &::after
          content: ''
          display: block
          height: 1px
          width: 20px
          margin: 15px 0
          background-color: $light-color

      a
        display: block
        margin-top: 5px

  &__secondary
    padding: 15px 0
    background-color: $primary-dark-color
    +mq(tablet)
      .footer__copyright
        padding-top: 20px
        +font-size(8px)

    &__inner
      display: flex
      justify-content: space-between
      align-items: center
      +mq(tablet)
        flex-direction: column-reverse

    &__nav
      +mq(tablet)
        display: none

      a
        margin: 0 10px

  &__bottom
    background-color: $primary-dark-color
    border-top: 2px solid #939a9d
    +mq(tablet)
      display: none

    &__inner
      display: flex
      justify-content: flex-end
      align-items: center
      padding: 15px 0

    &__content
      max-width: 460px
      width: 100%
      text-align: right
      padding-right: 15px

.footer__main
  &__inner
    justify-content: space-between
  .column
    max-width: 150px

.footer__extranet__main
  &__inner
    position: relative

  .column
    margin-right: 110px
    max-width: 160px
    &:last-child
      position: absolute
      right: 0
      padding-right: 25px
      margin-right: 0
