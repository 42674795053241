.block-title 
  margin: 0 auto
  padding: 60px 0 70px
  background: $primary-color
  color: $light-color
  +mq(mobile)
    padding: 40px 0

  .section-title
    text-align: center

  &__content
    color: inherit

    strong,
    h1, h2, h3, h4, h5, h6
      color: $light-color

    a
      text-decoration: underline
