[data-page="home"]
  .page__content
    +mq(mobile)
      flex-grow: 0

.home
  flex-grow: 1
  margin-top: 70px
  +mq(tablet)
    margin-top: 0

  &__intro
    display: flex
    height: 100%
    align-items: center
    +mq(tablet)
      height: auto
      align-items: flex-start

  &__nav
    display: flex
    justify-content: space-between
    +mq(tablet)
      flex-direction: column

    .item
      display: block
      max-width: 380px
      width: 100%
      background-size: cover
      background-position: center
      text-align: center
      overflow: hidden
      +mq(tablet)
        max-width: 100%
        margin-top: 20px
      +mq(mobile)
        margin-top: 15px

      &:hover
        text-decoration: none
        .item__content
          transform: none
          transition: 450ms $ease-out

      // Fix bug visuel arrondi pixel
      &:last-child
        transform: translateX(-1px)

      &__content
        display: flex
        flex-direction: column
        height: 100%
        padding: 40px 30px 30px
        color: $light-color
        transform: translateY(100%) translateY(-120px)
        transition: 150ms $ease-out
        +mq(tablet)
          padding: 30px
          transform: none

        &--1
          background-color: $student-color
          +mq(tablet)
            background-color: transparentize($student-color, .2)
        &--2
          background-color: $center-color
          +mq(tablet)
            background-color: transparentize($center-color, .2)
        &--3
          background-color: $partner-color
          +mq(tablet)
            background-color: transparentize($partner-color, .2)

      &__title
        font-weight: $bold
        line-height: 1
        text-transform: uppercase
        +font-size(18px)
        +mq(tablet)
          line-height: 1.4

      &__profile
        padding-top: 20px
        line-height: 1
        +mq(tablet)
          line-height: 1.4
        +mq(mobile)
          padding-top: 10px

      &__desc
        padding: 50px 0
        line-height: 2
        +font-size(12px)
        +mq(tablet)
          padding-top: 25px
        +mq(mobile)
          display: none

      .button
        align-self: center
        margin-top: auto
        +mq(tablet)
          display: none

  &__footer
    display: flex
    justify-content: space-between
    padding: 40px 0
    align-items: flex-end
    +mq(tablet)
      padding-top: 20px
      justify-content: flex-end

    &__nav
      +mq(tablet)
        display: none

      a
        margin-right: 15px
        text-decoration: underline
        opacity: .4
        +font-size(12px)

        &:hover
          opacity: 1

    &__logo
      display: flex
      align-items: center

      a
        margin-right: 20px
        font-weight: $bold
        +font-size(12px)

      img
        max-width: 70px

  &__hero
    display: none
    background-size: cover
    background-position: center
    color: $light-color
    +mq(tablet)
      display: flex

    &__inner
      width: 100%
      padding: 60px 0
      background-color: transparentize($dark-color, .2)
      text-align: center

    .section-title
      padding-bottom: 10px
      +mq(tablet)
        color: inherit !important


.home-video
  &__title
    padding: 40px 0
    text-align: center
    color: $primary-color

  &__thumb
    position: relative
    padding-top: 31.2%
    background-position: center
    background-size: cover
    +mq(tablet)
      max-width: calc(100% - 20px)
      width: 100%
      margin: 0 auto
      padding-top: 46.67%

  &__play
    position: absolute
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)

    svg
      width: 60px
      height: 60px
      fill: $light-color

.home-zoom
  margin-top: 75px
  padding: 40px 0
  background-color: $primary-color
  color: $light-color
  +mq(tablet)
    margin-top: 40px
    padding-bottom: 0

  &__inner
    display: flex
    justify-content: space-between
    align-items: center

  &__infos
    max-width: 480px
    width: 100%
    +mq(tablet)
      display: flex
      flex-direction: column
      margin: 0 auto

      .button
        order: 2
        margin: 0 auto
        transform: translateY(50%)
        background-color: $primary-dark-color
        color: $light-color

    img
      display: block
      margin-top: 20px
      width: 100%
      +mq(tablet)
        order: 1
        margin-top: 0

    &__content
      padding: 20px 0
      color: inherit
      +mq(tablet)
        order: 0

  &__quote
    display: flex
    justify-content: space-between
    align-items: center
    padding-left: 55px
    text-align: center
    +mq(tablet)
      display: none

    &__content
      max-width: 290px

      blockquote
        margin-top: 10px
        font-style: italic
        +font-size(22px)

    &__profile
      margin-left: 20px
      display: flex
      flex-direction: column
      align-items: center

      p
        margin-top: 20px
        font-weight: bold
        +font-size(12px)

    &__picture
      height: 160px
      width: 160px
      border-radius: 80px
      background-position: center
      background-size: cover

.home-student

  &__intro
    display: flex
    color: $light-color
    background-color: $student-color
    +mq(tablet)
      background-color: transparent
      flex-direction: column

    &__image
      max-width: 50%
      width: 100%
      background-position: center
      background-size: cover

    .half-wrapper
      position: relative
      display: flex
      justify-content: flex-end
      padding: 110px 0
      +mq(tablet)
        background-color: $student-color
        padding: 35px 50px
        max-width: calc(100% - 20px)
        width: 100%
        margin: -30px auto 0
        justify-content: center
      +mq(mobile)
        padding: 35px 10px

    &__content
      display: flex
      flex-direction: column
      align-items: center
      

      .divider
        padding: 10px 0
        align-self: flex-start
        text-transform: uppercase
        +mq(tablet)
          align-self: auto
          font-weight: $bold
          +font-size(12px)

      .button
        align-self: flex-start
        background-color: #596d00
        +font-size(12px)
        +mq(tablet)
          align-self: auto
          text-align: center

    &__title
      font-weight: bold
      +font-size(22px)
      +mq(tablet)
        text-align: center
        +font-size(20px)
      +mq(mobile)
        +font-size(18px)

    .location
      padding-top: 20px

.home-center
  &__intro
    padding: 40px 0
    background-color: $center-color
    color: $light-color
    +mq(tablet)
      flex-direction: column
      padding-top: 0

    &__inner
      display: flex
      +mq(tablet)
        flex-direction: column

    &__image
      display: flex
      justify-content: center
      align-items: center
      background-size: cover
      background-position: center
      max-width: 50%
      width: 100%
      height: 245px
      +mq(tablet)
        max-width: 100%

      svg
        width: 70px
        height: 70px
        fill: $light-color

    &__main
      padding: 30px 70px 0 0
      +mq(tablet)
        padding-right: 0
        padding-bottom: 20px
        text-align: center

    .content
      padding: 25px 0
      color: inherit

    .button
      color: $center-color
      +mq(tablet)
        margin: 0 auto

.home-map
  padding: 40px 0

  &__inner
    display: flex
    +mq(tablet)
      flex-direction: column

    img
      max-width: 50%
      width: 100%
      +mq(tablet)
        max-width: 100%

  &__form
    display: flex
    flex-direction: column
    justify-content: center
    max-width: 50%
    width: 100%
    padding-left: 40px
    +mq(tablet)
      align-items: center
      max-width: 100%
      padding-left: 0

    .divider
      padding: 10px 0
      text-transform: uppercase
      +mq(tablet)
        font-weight: $bold
        +font-size(12px)

    .button
      text-align: center
      +font-size(12px)

    &__title
      max-width: 250px
      line-height: 1.2
      font-weight: bold
      +font-size(22px)
      +mq(tablet)
        max-width: 100%
        text-align: center
        +font-size(20px)
      +mq(mobile)
        +font-size(18px)

    .location
      padding-top: 20px

.home-split
  display: flex
  +mq(tablet)
    flex-direction: column

  &__half
    max-width: 50%
    width: 100%
    +mq(tablet)
      max-width: 100%
      padding: 0 10px

    &--left
      display: flex
      justify-content: flex-end
      background-color: $medium-color
      +mq(tablet)
        justify-content: center
        padding-top: 20px
        padding-bottom: 20px

      .half-wrapper
        display: flex
        flex-direction: column
        justify-content: center

    &--primary
      background-color: $primary-color
  
    &--grey
      background-color: $medium-color

    &:last-child
      .half-wrapper
        display: flex
        justify-content: center
        +mq(tablet)
          max-width: 100%

        &--wrap
          flex-wrap: wrap


.home-ideas
  margin-bottom: 40px

  .section-title
    color: $center-color

  &__content
    padding: 20px 20px 0 0
    font-style: italic
    +mq(tablet)
      +font-size(14px)

  &__image
    padding: 70px 0
    background-color: $center-color
    +mq(tablet)
      padding: 30px 0
    +mq(mobile)
      padding: 15px 0

.home-partner
  &__intro

    .content
      padding: 20px 20px 0 0
      +mq(tablet)
        padding: 20px 0

    .section-title
      color: $partner-color
      +mq(tablet)
        padding-top: 20px

    &__image
      padding: 50px 0 20px
      background-color: $partner-color
      +mq(tablet)
        padding-top: 20px

      .half-wrapper
        flex-direction: column
        align-items: center
        color: $light-color

      img
        margin: 20px 0 10px
    
    &__title
      font-weight: bold
      +font-size(22px)
      +mq(tablet)
        +font-size(20px)
      +mq(mobile)
        +font-size(18px)
