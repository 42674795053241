.documents
  background-color: $medium-color
  padding-bottom: 30px

  .section-title
    color: $medium-color
    background-color: $primary-color
    text-align: center
    padding: 30px

  .wrapper
    max-width: 930px

    &.is-end
      .button
        display: none

  &__header
    display: flex
    justify-content: space-between
    padding: 40px 0
    color: rgba(0,0,0,.7)
    +font-size(12px)
    +mq(mobile)
      flex-direction: column
      padding: 15px 0

  &__filters
    display: flex
    +mq(mobile)
      flex-wrap: wrap

    span
      font-weight: bold
      color: $dark-color
      +mq(mobile)
        width: 100%

    .radio
      +mq(mobile)
        max-width: 50%
        width: 100%
      label
        margin-left: 20px
        +mq(mobile)
          margin-left: 0
          margin-top: 5px

  &__results
    +mq(mobile)
      padding-top: 15px

  &__inner
    position: relative
    display: flex
    flex-wrap: wrap
    margin: 0 -15px
    +mq(tablet)
      justify-content: center
    +mq(mobile)
      margin: 0

    &::after
      content: ''
      display: block
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 140px
      background-image: linear-gradient(to top, $medium-color, transparent)
      pointer-events: none

      .is-end &
        display: none

  .button
    margin: 30px auto 0
    text-transform: initial

.document
  display: flex
  flex-direction: column
  max-width: 160px
  width: 100%
  margin: 15px
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.17)
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  overflow: hidden

  @supports (display: grid)
    opacity: 0
    animation: slide-up 250ms ease-out forwards

  +mq(mobile)
    display: flex
    max-width: 100%
    border-bottom-left-radius: 5px
    border-top-left-radius: 5px
    border-bottom-right-radius: 0
    margin: 8px 0

  &__icon
    height: 100px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    +mq(mobile)
      max-width: 100px

    &--youtube
      background-color: $accent-color-2
    &--audio
      background-color: $accent-color-1
    &--pdf 
      background-color: $accent-color-3
    &--image 
      background-color: $primary-color
    
  svg
    fill: #fff
    height: 55px
    +mq(mobile)
      height: 40px

  &__info
    display: flex
    flex-direction: column
    flex-grow: 1
    background-color: $light-color
    min-height: 130px
    padding: 15px
    +mq(mobile)
      width: 100%
      min-height: auto

  &__title
    font-weight: $bold
    color: $primary-color
    line-height: 20px
    +font-size(16px)

  &__footer
    display: flex
    margin-top: auto
    padding-top: 5px
    width: 100%
    +font-size(12px)
    color: $primary-color
    line-height: 16px
    justify-content: space-between

  &__button
    background-color: $accent-color-3
    font-weight: $bold
    margin: 0 auto
    color: $medium-color

.select
  background-color: transparent
  border-radius: 0
  border: 0
  font: inherit
  line-height: 1
  color: $dark-color
  font-weight: bold
  -moz-appearance: none
  -webkit-appearance: none
  &:-moz-focusring 
    color: transparent
    text-shadow: 0 0 0 #000
  
  &::-ms-expand
    display: none
