.extra-documents
    +mq(tablet)
        .wrapper
            padding-right: 2%
            padding-left: 2%
        .triangle:after
            right: 2%
    +mq(mobile)
        .wrapper
            padding-right: 1%
            padding-left: 1%
        .triangle:after
            right: 1%

    &__category__title,
    &__sub-category__title
        font-weight: bold
        text-transform: uppercase
        line-height: 1
        +font-size(18px)
        +mq(tablet)
          +font-size(18px)
        +mq(mobile)
          +font-size(16px)

    &__category
        &__title
            color: $light-color
            background-color: $primary-color
            border: 1px solid $medium-dark-color
            padding: 48px 0 37px
            +mq(tablet)
                padding: 25px 0 18px

        &.is-open
            .triangle:after
                transform: translateY(-50%) rotate(90deg)

    &__sub-category
        &__title
            background-color: #f0f0f0
            color: $primary-color
            padding: 32px 0 28px
            +mq(tablet)
                padding: 20px 0 14px

    &__item
        +font-size(16px)
        +mq(tablet)
          +font-size(14px)
        +mq(mobile)
          +font-size(12px)

        &--reduce
            background-color: $primary-color
            color: $light-color
            border-top: 1px solid #f0f0f0
            &:last-child
                border-bottom: 1px solid #f0f0f0
            svg
                fill: $light-color

            .extra-documents__item__name
                //flex: 0 0 auto
                width: 35.714286% /* 400px/1120px */

            .extra-documents__item__category
                //flex: 0 0 auto
                width: 25% /* 280px/1120px */
            .extra-documents__item__update
                //flex: 1
                text-align: center
            &:hover
                border-top: 1px solid $primary-color
                &:last-child
                    border-bottom: 1px solid $primary-color

                .extra-documents__category__title--private span:after
                    +backgroundImage('lock-green.png', 100%, center)
        &--large,
        &--reduce:hover
            transition: 0.3s
            background-color: $light-color
            color: $primary-color
            border-top: 1px solid $primary-color
            &:last-child
                border-bottom: 1px solid #f0f0f0
            svg
                fill: $primary-color

        &--large
            .extra-documents__item__update
                margin-right: 100px
                +mq(tablet)
                  margin-right: 20px
                +mq(mobile)
                    margin-right: 10px

        &__inner
            display: flex
            align-items: center
            justify-content: space-between
            min-height: 95px
            cursor: pointer
            padding: 10px 0

        &__name
            font-weight: bold
            display: flex
            align-items: center
            padding-right: 10px
            +mq(tablet)
                width:  57%
            +mq(mobile)
                width: 60%
            svg
                margin-right: 25px
                width: 43px
                flex: 0 0 auto
                +mq(tablet)
                    margin-right: 20px
                +mq(mobile)
                    margin-right: 15px
                    width: 35px
        &__infos
            display: flex
            align-items: center
            +mq(mobile)
                width: 35%
                justify-content: space-between

        &__link
            display: flex
            align-items: center
            justify-content: center
            width: 30px
            height: 30px
            margin-left: 10px
            background-color: $primary-color
            +mq(mobile)
                flex: 0 0 auto

            svg
              height: 16px
              width: 8px
              fill: $light-color

.faq-bannier
    background-color: $accent-color-3
    padding: 25px 0
    border-top: 2px solid $light-color
    border-bottom: 2px solid $light-color
    &__columns
        display: flex
        justify-content: space-between
        align-items: center
    .faq-box__img
        max-width: 200px
        margin-left: 190px
        +mq(mobile)
            display: none
    .faq-box__title
        font-weight: bold
        margin-bottom: 30px
    .button
        display: block
        text-align: center