.block-video
  position: relative
  margin-top: 120px
  +mq(mobile)
    margin-top: 60px

  img
    display: block
    width: 100%

  &__button
    position: absolute
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)

    svg
      width: 70px
      height: 70px
      fill: $light-color