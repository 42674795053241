.news-all
  padding-top: 40px

  .section-title
    text-align: center 
    padding-bottom: 20px

  &__items
    display: flex
    flex-wrap: wrap
    margin: 0 -10px
    +mq(tablet)
      justify-content: center

    .news-cards__item
      padding-bottom: 60px
      margin: 0 10px

  .button--primary
    margin: 0 auto
    +mq(tablet)
      margin-bottom: 30px
