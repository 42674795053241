.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  border-bottom: 1px solid $medium-dark-color
  background-color: $light-color
  z-index: z(nav)

  &__inner
    position: relative
    display: flex
    align-items: center
    padding: 20px 0
    z-index: z(menu-actions)
    transition: 300ms $ease-out
    +mq(tablet)
      width: 100%
      padding: 0
      justify-content: space-between
      align-items: stretch

    .is-compact &
      padding: 10px 0
      +mq(tablet)
        padding: 0

  &__logo
    display: flex
    +mq(tablet)
      padding: 15px 25px
    +mq(mobile)
      padding: 10px
      padding-right: 5px

    img
      width: 80px
      +mq(tablet)
        width: 60px
      +mq(mobile)
        width: 40px

    &__title
      display: flex
      flex-direction: column
      justify-content: center
      padding-left: 20px
      margin-left: 20px
      border-left: 2px solid $medium-color
      +mq(tablet)
        border-left: 0
        margin-left: 0
        padding-left: 15px

      div
        font-weight: $bold
        text-transform: uppercase
        +font-size(20px)
        +mq(tablet)
          +font-size(14px)
        +mq(mobile)
          +font-size(11px)
      
      span
        font-weight: $light
        font-style: italic
        +font-size(18px)
        +mq(tablet)
          +font-size(14px)
        +mq(mobile)
          +font-size(12px)

  &__trigger
    display: none
    width: 100px
    background-color: $primary-color
    +mq(tablet)
      display: block
    +mq(mobile)
      width: 65px

    svg
      fill: $light-color
      width: 40px
      height: 40px
      +mq(mobile)
        width: 24px
        height: 24px

  &__mobile
    margin-left: auto
    margin-bottom: auto
    +mq(tablet)
      position: fixed
      top: 0
      left: 0
      display: flex
      flex-direction: column
      width: 100%
      height: 100vh
      background-color: $light-color
      transform: translate3d(-100%, 0, 0)
      transition: 400ms $ease-out
      overflow-y: auto
      z-index: z(nav)

      &.is-visible
        transform: none

    &__trigger
      display: none
      width: 100px
      height: 100px
      background-color: $primary-color
      margin-left: auto
      flex-shrink: 0
      +mq(tablet)
        display: block
      +mq(mobile)
        width: 65px
        height: 65px

      svg
        fill: $light-color
        width: 40px
        height: 40px
        +mq(mobile)
          width: 24px
          height: 24px

    &__search
      display: flex
      align-items: center
      padding: 0 15px 15px
      color: $primary-color
      font-weight: $semi-bold
      +mq(tablet)
        +font-size(18px)
      +mq(mobile)
        +font-size(16px)

      &:hover
        text-decoration: none

    &__actions
      display: none
      +mq(tablet)
        display: flex
        flex-direction: column
        flex: 1

  &__nav
    display: flex
    transition: 300ms $ease-out
    +mq(tablet)
      flex-direction: column
      padding: 15px

    .is-compact & 
      transform: translateY(-150%)
      +mq(tablet)
        transform: none

    &__item
      display: flex
      align-items: center
      position: relative
      margin-right: 20px
      padding: 12px 0
      color: $primary-color
      font-weight: $semi-bold
      +font-size(14px)
      +mq(tablet)
        margin-right: 0
        margin-top: 10px
        padding: 12px
        border-left: 4px solid
        +font-size(18px)

      +mq(mobile)
        +font-size(16px)

      &::before
        content: ''
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 3px
        transform: translateY(8px)
        opacity: 0
        transition: 300ms $ease-out
        +mq(tablet)
          display: none

      &:hover
        text-decoration: none

        &::before
          transform: none
          opacity: 1

      &.is-active::before
        transform: none
        opacity: 1

      &--student
        border-color: $student-color
        &::before
          background-color: $student-color
        &.is-active
          color: $student-color

      &--center
        border-color: $center-color
        &::before
          background-color: $center-color
        &.is-active
          color: $center-color

      &--partner
        border-color: $partner-color
        &::before
          background-color: $partner-color
        &.is-active
          color: $partner-color

      svg
        width: 30px
        height: 30px
        margin-right: 10px
        fill: currentColor

      &.is-active
        border-color: currentColor

  &__actions
    display: flex
    position: absolute
    bottom: 0
    right: 0
    transform: translateY(50%)
    transition: 300ms $ease-out
    +mq(tablet)
      display: none

    .is-compact &
      transform: translateY(-50%)

    &__search
      display: flex
      align-items: center
      justify-content: center
      height: 50px
      width: 50px
      margin-right: 20px
      background-color: $light-color
      border-radius: 25px
      border: 1px solid $medium-dark-color
      +mq(tablet)
        height: 42px
        width: 42px
        margin-right: 15px

      svg
        width: 16px
        height: 22px
        fill: $primary-color
  
    &__menu
      &.is-open
        background-color: $accent-color-2

  &__actions,
  &__extranet__actions
    &__menu
      display: flex
      align-items: center
      background-color: $accent-color-3
      color: $light-color
      font-weight: $bold

      &.is-open
        .header__actions__menu--close,
        .header__extranet__actions__menu--close
          display: flex
        .header__actions__menu--open,
        .header__extranet__actions__menu--open
          display: none

      svg
        fill: $light-color
        width: 15px
        height: 15px
        margin-left: 5px

      &--open,
      &--close
        display: flex
        align-items: center
        padding: 0 30px

      &--close
        display: none