.login
    margin-top: 60px
    display: flex
    min-height: 440px
    +mq(mobile)
        flex-wrap: wrap
    &.wrapper
        max-width: 780px

    &__title
        font-weight: bold
        line-height: 1.285
        text-transform: uppercase
        margin-bottom: 20px

    &__welcome
        width: 360px
        text-align: center
        background: $primary-color
        flex: 0 0 auto
        +mq(tablet)
            width: 45%
        +mq(mobile)
            width: 100%
        &__image
            height: 345px
            +backgroundImage('extranet.jpg', cover, center)
            +mq(mobile)
                display: none
        &__title,
        &__subtitle
            color: $light-color
            padding: 0 10px
        &__title
            margin-top: 20px
            font-weight: bold
            text-transform: uppercase
            line-height: 1.3
            +font-size(18px)
        &__subtitle
            margin-top: 8px
            line-height: 1.285
            +font-size(16.39px)
            +mq(tablet)
                margin-bottom: 20px

    &__text
        padding: 40px 25px 0
        background: $medium-color
        color: $primary-color
        line-height: 1.285
        flex: 1
        +font-size(14px)

    &__form
        margin-top: 55px
        padding-bottom: 25px
        +mq(mobile)
            margin-top: 30px
        &--small
            margin-top: 40px
        &__lost-pasword
            margin-top: 5px
        &__submit
            display: block
            margin-left: auto
            font-weight: bold
            margin-top: 8px
            &--bottom
                margin-top: 35px
        &__error
            margin-bottom: 10px
            color: $error-form-color
        &__back
            margin-top: 85px
            display: block

    .extranet__form__input
        margin-bottom: 18px
        &--small
            margin-bottom: 12px
