input
  border: 0
  border-radius: 0
  background-color: transparent
  font: inherit

.newsletter
  form
    display: flex
    align-items: flex-start
    +mq(tablet)
      flex-direction: column

    // &.is-loading
    //   span
    //     display: none
    //   img
    //     display: block !important

  input[type="email"]
    height: 40px
    width: 240px
    padding: 0 10px
    background-color: $light-color
    +mq(tablet)
      margin-top: 20px

  button[type="submit"]
    display: flex
    align-items: center
    justify-content: center
    height: 40px
    padding: 0 20px
    font-weight: $bold
    color: $light-color
    +mq(tablet)
      width: 100%
      margin-top: 10px
      background-color: $primary-dark-color !important

    .footer__newsletter--1 &
      background-color: #596d00
    .footer__newsletter--2 & 
      background-color: #73014d
    .footer__newsletter--3 &
      background-color: #004759

    // img
    //   animation: spin 800ms linear infinite
    //   width: 20px
    //   height: 20px
    //   display: none

  &__error
    padding-top: 5px 

.location
  display: flex
  width: 100%
  +mq(tablet)
    justify-content: center

  &.location--green
    input[type="search"]
      background-color: $light-color
    
    input[type="submit"]
      background-color: #f6f6f6
      color: $student-color

  &.location--pink
    input[type="search"]
      background-color: $medium-color
    
    input[type="submit"]
      background-color: $center-color
      color: $light-color

  &.location--extranet
    input[type="submit"]
      color: $accent-color-2
    input[type="search"]
      max-width: none

  input[type="search"]
    max-width: 325px
    width: 100%
    flex-grow: 1
    height: 40px
    padding: 0 20px
    +font-size(12px)
    +placeholder(#8e8e8e)

  input[type="submit"]
    display: block
    height: 40px
    width: 40px
    font-weight: bold
    cursor: pointer

.radio,
.checkbox
  label
    position: relative
    display: flex
    align-items: center

    &::before
      content: ''
      display: block
      flex-shrink: 0
      width: 14px
      height: 14px
      margin-right: 8px
      border: 1px solid rgba(0, 0, 0, 0.17)
      background-color: $light-color

  input
    display: none

    &:checked + label
      &::before 
        background-color: $accent-color-3
