.flash-success
    display: none

.page__extranet
    .header__inner
        justify-content: space-between
    .header__logo
        +mq(tablet)
            padding-top: 8px
            padding-bottom: 8px
        +mq(mobile)
            padding: 7px
        a
            +mq(tablet)
                display: flex
                flex-flow: column
                align-items: center
            +mq(mobile)
                width: 45px
        img
            +mq(tablet)
                width: 51.7px
            +mq(mobile)
                width: 37px
        &__title
            +mq(mobile)
                padding-left: 10px
        &__extranet
            margin-top: 5px
            .button
                padding: 7px
                +font-size(10px)
                +mq(mobile)
                    padding: 3px
                    +font-size(8px)

.header
    &__logo__subtitle__button
        display: inline-block
        vertical-align: middle
        margin-left: 10px
        +mq(tablet)
            display: none
        .button
            padding: 8px 15px
            font-style: normal
            font-weight: 600
            +font-size(12px)

    &__extranet__actions
        padding-top: 22px
        display: flex
        +mq(tablet)
            padding-top: 0

        &--unic
            padding: 10px 20px
            font-weight: 600
            display: flex
            align-items: center
            +font-size(12px)
            svg
                fill: $light-color
                height: 18px
                width: 18px
                margin-right: 7px

        &--stat,
        &__menu
            padding-top: 16px
            padding-bottom: 16px
            font-weight: bold
            z-index: z(nav)
            +font-size(17.82px)
            +mq(tablet)
                padding: 0
                span
                    display: none
                svg
                    width: 40px
                    height: 40px
            +mq(mobile)
                svg
                    width: 24px
                    height: 24px


        &--stat
            margin-right: 16px
            +mq(tablet)
                display: none
                width: 100px
                justify-content: center
            +mq(mobile)
                width: 65px
                margin-right: 10px
            svg
                margin-left: 7px
                transform: rotate(-90deg)
                +mq(tablet)
                    margin-left: 0
        &__menu
            line-height: 1
            &--open,
            &--close
                padding: 0
                width: 140px
                justify-content: center
                +mq(tablet)
                    width: 100px
                +mq(mobile)
                    width: 65px
