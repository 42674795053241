@mixin animation($name, $duration, $delay: 0, $timingFunction: ease)
    animation-name: $name
    animation-duration: $duration
    animation-delay: $delay
    animation-timing-function: $timingFunction
    animation-fill-mode: forwards

.animation-fade
    &-enter, &-appear
        opacity: 0

        &-active
            opacity: 1
            transition: opacity 1s ease

    &-leave
        opacity: 1

        &-active
            opacity: 0
            transition: opacity 1s ease

.animation-slide

    &-enter
        max-height: 0
        transition: all 0.9s ease

        &-active
            max-height: 500px

    &-leave
        max-height: 500px
        transition: all 0.3s ease

        &-active
            max-height: 0

@keyframes slideInRtoL
    0%
        left: 100%
    100%
        left: 0


@keyframes slideOutRtoL
    0%
        left: 0
    100%
        left: -100%

@keyframes slideInLtoR
    0%
        left: -100%
    100%
        left: 0


@keyframes slideOutLtoR
    0%
        left: 0
    100%
        left: 100%

@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1


@keyframes fadeOut
    0%
        opacity: 1
    100%
        opacity: 0


@keyframes slideUp
    0%
        transform: translate3d(0, 6rem, 0)
        opacity: 0
    100%
        transform: translate3d(0, 0, 0)
        opacity: 1


@keyframes smallBounce
    0%
        transform: translateY(0)
    50%
        transform: translateY(5px)
    100%
        transform: translateY(0)


@keyframes fadeSlideLtoR
    0%
        opacity: 0
        transform: translateX(-100%)
    100%
        opacity: 1
        transform: translateX(0)

@keyframes fadeSlideRtoL
    0%
        opacity: 0
        transform: translateX(100%)
    100%
        opacity: 1
        transform: translateX(0)

@keyframes focus
    0%
        filter: blur(5px)
    // transform: scale(1.3)
    // opacity: 0
    100%
        filter: blur(0)
// transform: scale(1)
// opacity: 1

@keyframes rotate
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(180deg)

@keyframes zoom
    0%
        transform: scale(0)
    100%
        transform: scale(1)
