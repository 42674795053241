.button
  display: table
  padding: 12px 40px
  line-height: 1
  text-transform: uppercase
  +font-size(14px)
  +mq(tablet)
    text-transform: initial
    font-weight: $bold

  &:hover
    text-decoration: none

  &--loading
    img
      animation: spin 800ms linear infinite
      width: 20px
      height: 20px
      display: none

    &.is-loading,
    .is-loading &
      span
        display: none
      img
        display: block !important

  &--outline
    border: 1px solid $light-color

  &--green
    background-color: $accent-color-1
    color: $light-color

  &--pink
    background-color: $accent-color-2
    color: $light-color

  &--blue
    background-color: $accent-color-3
    color: $light-color

  &--primary
    background-color: $primary-color
    color: $light-color

  &--light
    background-color: $light-color
    color: $primary-color

  &--grey
    background-color: #6a6a6a
    color: $light-color

  &--small
    padding-left: 15px
    padding-right: 15px
    +font-size(12px)

  &--question
      position: relative
      &--compact::before
        left: 20%
      &::before
        content: ''
        display: block
        position: absolute
        top: 50%
        transform: translateY(-50%)
        left: 4.7%
        width: 5.3%
        padding-bottom: 9.3%
        +backgroundImage('question-mark.png', cover, center)

  &--large
    font-weight: bold
    +font-size(24px)
    +mq(tablet)
      +font-size(20px)
    +mq(mobile)
      +font-size(18px)

  &--extra
    border: 2px solid $light-color
    font-weight: 600
    background-color: transparent
    color: $light-color

  &--icon
    display: flex
    align-items: center
    svg
      fill: $light-color
      width: 15px
      height: 15px
      margin-left: 5px