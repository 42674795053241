.modal
  display: none

  &.is-open
    display: block

  &__overlay
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    padding: 0 10px
    background-color: rgba(#000, .6)
    z-index: z(overlay)
    overflow-y: auto

  &__container
    max-width: 980px
    width: 100%

  &__inner
    overflow: hidden
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)

  &__close
    position: absolute
    top: 20px
    right: 20px
    margin-left: auto
    pointer-events: none

    svg
      width: 20px
      height: 20px
      fill: $light-color

  // Animations

  &[aria-hidden="false"] .modal__overlay
    animation: overlay-fade-in 300ms $ease-out

  &[aria-hidden="false"] .modal__container
    animation: modal-slide-up 300ms $ease-out

  &[aria-hidden="true"] .modal__overlay
    animation: overlay-fade-out 300ms $ease-out

  &[aria-hidden="true"] .modal__container
    animation: modal-slide-down 300ms $ease-out

.modal--video
  .modal__inner
    position: relative
    padding-bottom: 56.25% // 16/9
    height: 0
    width: 100%
    background-color: $dark-color

    iframe, object, embed, video
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

.modal--audio
  .modal__container
    max-width: 560px
  audio
    width: 100%

.modal--image
  .modal__container
    width: auto
  .modal__inner
    text-align: center
  img
    max-width: 100%

.modal--results
  .modal__inner
    background-color: $light-color

  .modal__container
    max-width: 360px
    width: 100%

  .result
    margin: 0
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)
    +mq(tablet)
      max-width: 100%
    &__section
      padding-left: 0

@keyframes overlay-fade-in
  from
    opacity: 0
  to
    opacity: 1

@keyframes overlay-fade-out
  from
    opacity: 1
  to
    opacity: 0

@keyframes modal-slide-up
  from
    transform: translateY(15%)
  to
    transform: none

@keyframes modal-slide-down
  from
    transform: none
  to
    transform: translateY(15%)
