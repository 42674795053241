html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Open Sans";
  src: url("/web/fonts/OpenSans-LightItalic.woff2") format("woff2"), url("/web/fonts/OpenSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("/web/fonts/OpenSans-Regular.woff2") format("woff2"), url("/web/fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/web/fonts/OpenSans-Bold.woff2") format("woff2"), url("/web/fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/web/fonts/OpenSans-SemiBold.woff2") format("woff2"), url("/web/fonts/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("/web/fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"), url("/web/fonts/OpenSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
html {
  font-size: 62.5%;
  height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  background: #fff;
  color: #050505;
  font-family: "Open Sans", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.667;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  overflow-x: hidden;
  font-size: 1.6rem;
}

.no-scroll {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

button,
input[type=button],
input[type=submit] {
  margin: 0;
  padding: 0;
  background: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  font: inherit;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
}

picture {
  display: block;
}

svg {
  width: auto;
  height: 100%;
}

.Icon {
  display: inline-block;
}

#root {
  min-height: 100vh;
  opacity: 1 !important;
  transition: opacity 600ms ease;
}
#root.root--columns {
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}
.page__content {
  flex-grow: 1;
  padding-top: 125px;
}
@media screen and ( max-width: 980px ) {
  .page__content {
    padding-top: 95px;
  }
}
@media screen and (max-width: 700px ) {
  .page__content {
    padding-top: 65px;
  }
}

.wrapper {
  max-width: 1120px;
  width: calc(100% - 20px);
  margin: 0 auto;
}
.wrapper--small {
  max-width: 990px;
}

.half-wrapper {
  max-width: 560px;
  width: 100%;
}
.half-wrapper--small {
  max-width: 510px;
}

.section-title {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .section-title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px ) {
  .section-title {
    font-size: 1.8rem;
  }
}

.section-subtitle {
  font-style: italic;
  font-weight: 300;
  font-size: 2rem;
}
@media screen and ( max-width: 980px ) {
  .section-subtitle {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .section-subtitle {
    font-size: 1.6rem;
  }
}

.dot--blue:before {
  background-color: #315562;
  margin-bottom: 1px;
}
.dot--green:before {
  background-color: #95b700;
}
.dot:before {
  content: "";
  height: 13px;
  width: 13px;
  display: inline-block;
  margin-right: 16px;
}

.content {
  color: #050505;
  font-size: 1.4rem;
}
.content u {
  text-decoration: underline;
  text-decoration-color: #315562;
}
.content em {
  font-style: italic;
}
.content strong {
  font-weight: bold;
  color: #315562;
}
.content p {
  padding-top: 20px;
}
.content li {
  padding-top: 10px;
  list-style-type: disc;
  list-style-position: inside;
}
.content img {
  width: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  from {
    transform: rotate(-360deg);
  }
}
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(15%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.animation-fade-enter, .animation-fade-appear {
  opacity: 0;
}
.animation-fade-enter-active, .animation-fade-appear-active {
  opacity: 1;
  transition: opacity 1s ease;
}
.animation-fade-leave {
  opacity: 1;
}
.animation-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease;
}

.animation-slide-enter {
  max-height: 0;
  transition: all 0.9s ease;
}
.animation-slide-enter-active {
  max-height: 500px;
}
.animation-slide-leave {
  max-height: 500px;
  transition: all 0.3s ease;
}
.animation-slide-leave-active {
  max-height: 0;
}

@keyframes slideInRtoL {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
@keyframes slideOutRtoL {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes slideInLtoR {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
@keyframes slideOutLtoR {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideUp {
  0% {
    transform: translate3d(0, 6rem, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes smallBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fadeSlideLtoR {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeSlideRtoL {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes focus {
  0% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.animated--delay-01, .animated--delay-01 > *::after {
  animation-delay: 0.1s !important;
}
.animated--delay-02, .animated--delay-02 > *::after {
  animation-delay: 0.2s !important;
}
.animated--delay-03, .animated--delay-03 > *::after {
  animation-delay: 0.3s !important;
}
.animated--delay-04, .animated--delay-04 > *::after {
  animation-delay: 0.4s !important;
}
.animated--delay-05, .animated--delay-05 > *::after {
  animation-delay: 0.5s !important;
}
.animated--delay-06, .animated--delay-06 > *::after {
  animation-delay: 0.6s !important;
}
.animated--delay-07, .animated--delay-07 > *::after {
  animation-delay: 0.7s !important;
}
.animated--delay-08, .animated--delay-08 > *::after {
  animation-delay: 0.8s !important;
}
.animated--delay-09, .animated--delay-09 > *::after {
  animation-delay: 0.9s !important;
}
.animated--delay-10, .animated--delay-10 > *::after {
  animation-delay: 1s !important;
}
.animated--delay-11, .animated--delay-11 > *::after {
  animation-delay: 1.1s !important;
}
.animated--delay-12, .animated--delay-12 > *::after {
  animation-delay: 1.2s !important;
}
.animated--delay-14, .animated--delay-14 > *::after {
  animation-delay: 1.4s !important;
}
.animated--delay-16, .animated--delay-16 > *::after {
  animation-delay: 1.6s !important;
}
.animated--delay-18, .animated--delay-18 > *::after {
  animation-delay: 1.8s !important;
}
.animated--delay-20, .animated--delay-20 > *::after {
  animation-delay: 2s !important;
}
.animated--delay-22, .animated--delay-22 > *::after {
  animation-delay: 2.2s !important;
}
.animated--delay-24, .animated--delay-24 > *::after {
  animation-delay: 2.4s !important;
}
.animated--delay-26, .animated--delay-26 > *::after {
  animation-delay: 2.6s !important;
}
.animated--delay-28, .animated--delay-28 > *::after {
  animation-delay: 2.8s !important;
}
.animated--delay-30, .animated--delay-30 > *::after {
  animation-delay: 3s !important;
}
.animated--delay-32, .animated--delay-32 > *::after {
  animation-delay: 3.2s !important;
}
.animated--delay-34, .animated--delay-34 > *::after {
  animation-delay: 3.4s !important;
}
.animated--delay-36, .animated--delay-36 > *::after {
  animation-delay: 3.6s !important;
}
.animated.slideUp {
  opacity: 0;
}
.animated.slideUp.in {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.animated.fadeSlideLtoR {
  opacity: 0;
}
.animated.fadeSlideLtoR.in {
  animation-name: fadeSlideLtoR;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.animated.fadeSlideRtoL {
  opacity: 0;
}
.animated.fadeSlideRtoL.in {
  animation-name: fadeSlideRtoL;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.animated.focus {
  filter: blur(5px);
}
.animated.focus.in {
  animation-name: focus;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.animated.fadeIn {
  opacity: 0;
}
.animated.fadeIn.in {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.animated.zoom {
  transform: scale(0);
}
.animated.zoom.in {
  animation-name: zoom;
  animation-duration: 1s;
  animation-delay: 0;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight {
  height: auto;
}
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-container-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}

.swiper-pagination-lock {
  display: none;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active {
  pointer-events: auto;
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}

.button, .faq-box__button {
  display: table;
  padding: 12px 40px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .button, .faq-box__button {
    text-transform: initial;
    font-weight: bold;
  }
}
.button:hover, .faq-box__button:hover {
  text-decoration: none;
}
.button--loading img {
  animation: spin 800ms linear infinite;
  width: 20px;
  height: 20px;
  display: none;
}
.button--loading.is-loading span, .is-loading .button--loading span {
  display: none;
}
.button--loading.is-loading img, .is-loading .button--loading img {
  display: block !important;
}
.button--outline {
  border: 1px solid #fff;
}
.button--green {
  background-color: #95b700;
  color: #fff;
}
.button--pink {
  background-color: #a5016f;
  color: #fff;
}
.button--blue {
  background-color: #007d9d;
  color: #fff;
}
.button--primary {
  background-color: #315562;
  color: #fff;
}
.button--light {
  background-color: #fff;
  color: #315562;
}
.button--grey {
  background-color: #6a6a6a;
  color: #fff;
}
.button--small {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.2rem;
}
.button--question {
  position: relative;
}
.button--question--compact::before {
  left: 20%;
}
.button--question::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4.7%;
  width: 5.3%;
  padding-bottom: 9.3%;
  background-image: url("/web/img/question-mark.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.button--large {
  font-weight: bold;
  font-size: 2.4rem;
}
@media screen and ( max-width: 980px ) {
  .button--large {
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px ) {
  .button--large {
    font-size: 1.8rem;
  }
}
.button--extra {
  border: 2px solid #fff;
  font-weight: 600;
  background-color: transparent;
  color: #fff;
}
.button--icon {
  display: flex;
  align-items: center;
}
.button--icon svg {
  fill: #fff;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

input {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font: inherit;
}

.newsletter form {
  display: flex;
  align-items: flex-start;
}
@media screen and ( max-width: 980px ) {
  .newsletter form {
    flex-direction: column;
  }
}
.newsletter input[type=email] {
  height: 40px;
  width: 240px;
  padding: 0 10px;
  background-color: #fff;
}
@media screen and ( max-width: 980px ) {
  .newsletter input[type=email] {
    margin-top: 20px;
  }
}
.newsletter button[type=submit] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  font-weight: bold;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .newsletter button[type=submit] {
    width: 100%;
    margin-top: 10px;
    background-color: #17272d !important;
  }
}
.footer__newsletter--1 .newsletter button[type=submit] {
  background-color: #596d00;
}
.footer__newsletter--2 .newsletter button[type=submit] {
  background-color: #73014d;
}
.footer__newsletter--3 .newsletter button[type=submit] {
  background-color: #004759;
}
.newsletter__error {
  padding-top: 5px;
}

.location {
  display: flex;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .location {
    justify-content: center;
  }
}
.location.location--green input[type=search] {
  background-color: #fff;
}
.location.location--green input[type=submit] {
  background-color: #f6f6f6;
  color: #95b700;
}
.location.location--pink input[type=search] {
  background-color: #f1f3f2;
}
.location.location--pink input[type=submit] {
  background-color: #a5016f;
  color: #fff;
}
.location.location--extranet input[type=submit] {
  color: #a5016f;
}
.location.location--extranet input[type=search] {
  max-width: none;
}
.location input[type=search] {
  max-width: 325px;
  width: 100%;
  flex-grow: 1;
  height: 40px;
  padding: 0 20px;
  font-size: 1.2rem;
}
.location input[type=search]:-ms-input-placeholder {
  color: #8e8e8e;
  font-style: normal;
}
.location input[type=search]::-webkit-input-placeholder {
  color: #8e8e8e;
  font-style: normal;
}
.location input[type=search]::placeholder {
  color: #8e8e8e;
  opacity: 1;
  font-style: normal;
}
.location input[type=search]:focus:-ms-input-placeholder {
  color: #c1c1c1;
}
.location input[type=search]:focus::-webkit-input-placeholder {
  color: #c1c1c1;
}
.location input[type=search]:focus::placeholder {
  color: #c1c1c1;
}
.location input[type=submit] {
  display: block;
  height: 40px;
  width: 40px;
  font-weight: bold;
  cursor: pointer;
}

.radio label,
.checkbox label {
  position: relative;
  display: flex;
  align-items: center;
}
.radio label::before,
.checkbox label::before {
  content: "";
  display: block;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.17);
  background-color: #fff;
}
.radio input,
.checkbox input {
  display: none;
}
.radio input:checked + label::before,
.checkbox input:checked + label::before {
  background-color: #007d9d;
}

.modal {
  display: none;
}
.modal.is-open {
  display: block;
}
.modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  overflow-y: auto;
}
.modal__container {
  max-width: 980px;
  width: 100%;
}
.modal__inner {
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  margin-left: auto;
  pointer-events: none;
}
.modal__close svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.modal[aria-hidden=false] .modal__overlay {
  animation: overlay-fade-in 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.modal[aria-hidden=false] .modal__container {
  animation: modal-slide-up 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.modal[aria-hidden=true] .modal__overlay {
  animation: overlay-fade-out 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.modal[aria-hidden=true] .modal__container {
  animation: modal-slide-down 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}

.modal--video .modal__inner {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  background-color: #050505;
}
.modal--video .modal__inner iframe, .modal--video .modal__inner object, .modal--video .modal__inner embed, .modal--video .modal__inner video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal--audio .modal__container {
  max-width: 560px;
}
.modal--audio audio {
  width: 100%;
}

.modal--image .modal__container {
  width: auto;
}
.modal--image .modal__inner {
  text-align: center;
}
.modal--image img {
  max-width: 100%;
}

.modal--results .modal__inner {
  background-color: #fff;
}
.modal--results .modal__container {
  max-width: 360px;
  width: 100%;
}
.modal--results .result {
  margin: 0;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
@media screen and ( max-width: 980px ) {
  .modal--results .result {
    max-width: 100%;
  }
}
.modal--results .result__section {
  padding-left: 0;
}

@keyframes overlay-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes overlay-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes modal-slide-up {
  from {
    transform: translateY(15%);
  }
  to {
    transform: none;
  }
}
@keyframes modal-slide-down {
  from {
    transform: none;
  }
  to {
    transform: translateY(15%);
  }
}
.menu,
.menu__extranet {
  position: absolute;
  right: 0;
  height: calc(100vh - 100px);
  width: 100%;
  transition: 600ms cubic-bezier(0.175, 0.885, 0.32, 1);
  transform: translate3d(100%, 0, 0);
  color: #fff;
  z-index: 4;
}
@media screen and ( max-width: 980px ) {
  .menu,
.menu__extranet {
    max-width: 100%;
    height: auto;
    flex: 1;
    overflow-y: visible;
  }
}
.menu.is-open,
.menu__extranet.is-open {
  transform: none;
}
.menu__overlay,
.menu__extranet__overlay {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.menu__wrapper,
.menu__extranet__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 740px;
  width: 100%;
  background-color: #007d9d;
}
.menu__inner,
.menu__extranet__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 100px;
  overflow-y: auto;
}
@media screen and ( max-width: 980px ) {
  .menu__inner,
.menu__extranet__inner {
    margin-bottom: 15px;
    padding: 0 15px;
  }
}
.menu__home,
.menu__extranet__home {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #315562;
  font-size: 1.2rem;
}
.menu__home:hover,
.menu__extranet__home:hover {
  text-decoration: none;
}
.menu__links,
.menu__extranet__links {
  padding-top: 80px;
}
.menu__dropdown,
.menu__extranet__dropdown {
  padding-bottom: 10px;
  font-weight: 600;
}
.menu__dropdown.is-visible .menu__dropdown__links,
.menu__extranet__dropdown.is-visible .menu__dropdown__links {
  display: block;
}
.menu__dropdown.is-visible .menu__dropdown__trigger svg,
.menu__extranet__dropdown.is-visible .menu__dropdown__trigger svg {
  transform: rotate(-180deg);
}
.menu__dropdown__trigger,
.menu__extranet__dropdown__trigger {
  display: flex;
  align-items: center;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .menu__dropdown__trigger,
.menu__extranet__dropdown__trigger {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .menu__dropdown__trigger,
.menu__extranet__dropdown__trigger {
    font-size: 1.6rem;
  }
}
.menu__dropdown__trigger:hover,
.menu__extranet__dropdown__trigger:hover {
  text-decoration: none;
}
.menu__dropdown__trigger svg,
.menu__extranet__dropdown__trigger svg {
  margin-left: 10px;
  width: 12px;
  height: 12px;
  fill: #fff;
  transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and (max-width: 700px ) {
  .menu__dropdown__trigger svg,
.menu__extranet__dropdown__trigger svg {
    width: 8px;
    height: 8px;
  }
}
.menu__dropdown__links,
.menu__extranet__dropdown__links {
  padding: 10px 0 20px;
  padding-left: 30px;
  display: none;
}
.menu__dropdown__links a,
.menu__extranet__dropdown__links a {
  opacity: 0.4;
  transform: opacity 400ms ease;
  font-size: 1.8rem;
}
@media screen and (max-width: 700px ) {
  .menu__dropdown__links a,
.menu__extranet__dropdown__links a {
    font-size: 1.6rem;
  }
}
.menu__dropdown__links a:hover,
.menu__extranet__dropdown__links a:hover {
  opacity: 0.8;
  text-decoration: none;
}
.menu__footer,
.menu__extranet__footer {
  padding: 80px 0;
  margin-top: auto;
}
@media screen and ( max-width: 980px ) {
  .menu__footer,
.menu__extranet__footer {
    padding-bottom: 30px;
  }
}
.menu__footer__social,
.menu__extranet__footer__social {
  display: flex;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .menu__footer__social,
.menu__extranet__footer__social {
    justify-content: center;
  }
}
@media screen and (max-width: 700px ) {
  .menu__footer__social .social-nav__item,
.menu__extranet__footer__social .social-nav__item {
    width: 32px;
    height: 32px;
  }
  .menu__footer__social .social-nav__item svg,
.menu__extranet__footer__social .social-nav__item svg {
    width: 12px;
    height: 12px;
  }
}
.menu__footer__social > span,
.menu__extranet__footer__social > span {
  margin-left: 10px;
  font-style: italic;
  font-weight: 600;
}
@media screen and (max-width: 700px ) {
  .menu__footer__social > span,
.menu__extranet__footer__social > span {
    font-size: 1.4rem;
  }
}
.menu__footer__newsletter,
.menu__extranet__footer__newsletter {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-style: italic;
  font-weight: 600;
}
@media screen and ( max-width: 980px ) {
  .menu__footer__newsletter,
.menu__extranet__footer__newsletter {
    margin: 20px auto 0;
  }
}
@media screen and (max-width: 700px ) {
  .menu__footer__newsletter,
.menu__extranet__footer__newsletter {
    font-size: 1.4rem;
  }
}
.menu__footer__newsletter svg,
.menu__extranet__footer__newsletter svg {
  width: 24px;
  height: 16px;
  margin-right: 10px;
  fill: #fff;
}

@media screen and ( max-width: 980px ) {
  .menu--corporate {
    position: static;
    transform: none;
    display: none;
  }
}

.menu__extranet__wrapper {
  position: relative;
  background-color: #315562;
}
.menu__extranet__home {
  background-color: #007d9d;
}
.menu__extranet__inner {
  padding: 0 120px;
}
@media screen and ( max-width: 980px ) {
  .menu__extranet__inner {
    padding: 0 10%;
  }
}
.menu__extranet__divide {
  color: #fff;
  margin-bottom: 40px;
}
.menu__extranet__dropdown {
  padding-bottom: 45px;
}
.menu__extranet__dropdown__trigger {
  line-height: 1;
}
@media screen and ( max-width: 980px ) {
  .menu__extranet__footer {
    padding-top: 40px;
  }
}
.menu__extranet__footer__contact {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 25px;
  font-size: 1.8rem;
}
.menu__extranet__footer__contact svg {
  width: 24px;
  height: 16px;
  margin-right: 20px;
  fill: #fff;
}

.social-nav {
  display: flex;
}
.social-nav__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  margin: 0 5px;
  border-radius: 16px;
  border: 2px solid #fff;
}
@media screen and ( max-width: 980px ) {
  .social-nav__item {
    height: 48px;
    width: 48px;
    border-radius: 24px;
  }
}
.social-nav__item svg {
  height: 12px;
  width: 12px;
  fill: #fff;
}
@media screen and ( max-width: 980px ) {
  .social-nav__item svg {
    height: 18px;
    width: 18px;
  }
}

.cookies,
.flash {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  background-color: #315562;
  color: #fff;
  z-index: 12;
  font-size: 1.2rem;
}
.cookies.is-visible,
.flash.is-visible {
  display: block;
}
.cookies__inner,
.flash__inner {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .cookies__inner,
.flash__inner {
    flex-direction: column;
  }
}
.cookies__buttons,
.flash__buttons {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: 50px;
}
@media screen and ( max-width: 980px ) {
  .cookies__buttons,
.flash__buttons {
    padding-left: 0;
    padding-top: 15px;
  }
}
.cookies__button,
.flash__button {
  display: block;
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}
.cookies__button--outline,
.flash__button--outline {
  border: 1px solid #fff;
  border-radius: 25px;
}

.cookies {
  display: none;
}

.flash {
  top: 0;
  bottom: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
.flash__inner {
  align-items: center;
  justify-content: space-between;
}
.flash__buttons {
  cursor: pointer;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .flash__buttons {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .flash__buttons {
    padding: 0;
    font-size: 3.5rem;
  }
}
.flash__buttons label {
  cursor: pointer;
}
.flash__input:checked + .flash {
  display: none;
}

.news-cards {
  overflow: hidden;
}
@media screen and ( max-width: 980px ) {
  .news-cards {
    background-color: #efefef;
    padding-bottom: 40px;
  }
}
.news-cards .section-title {
  padding: 40px 0;
  text-align: center;
}
.news-cards__inner {
  overflow: hidden;
}
.news-cards__item {
  max-width: 360px;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .news-cards__item {
    background-color: #fff;
  }
}
.news-cards__item a:hover {
  text-decoration: none;
}
.news-cards__item__thumb {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-position: center;
}
.news-cards__item__title {
  padding-top: 10px;
  font-weight: 600;
}
@media screen and ( max-width: 980px ) {
  .news-cards__item__title {
    padding: 20px 20px 0;
  }
}
.news-cards__item__content {
  padding-top: 5px;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .news-cards__item__content {
    padding: 0 20px 20px;
  }
}
.news-cards__item__insert {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #315562;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 27px;
  font-size: 1.4rem;
}
.news-cards__nav {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}
@media screen and ( max-width: 980px ) {
  .news-cards__nav {
    display: none;
  }
}
.news-cards__nav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-color: #95b700;
}
.news-cards__nav__item svg {
  height: 16px;
  width: 8px;
  fill: #fff;
}

.news-extranet .news-cards__nav__item {
  background-color: #315562;
}

.home-columns,
.home-columns-extranet {
  margin-top: 170px;
  background-color: #f1f3f2;
}
@media screen and ( max-width: 980px ) {
  .home-columns,
.home-columns-extranet {
    margin-top: 40px;
    background-color: transparent;
  }
}
@media screen and ( max-width: 980px ) {
  .home-columns--small,
.home-columns-extranet--small {
    margin: 30px 0;
  }
}
.home-columns--small .home-columns__inner,
.home-columns-extranet--small .home-columns__inner {
  transform: none;
}
.home-columns--small .home-columns__left,
.home-columns-extranet--small .home-columns__left {
  transform: translateY(-90px);
}
@media screen and ( max-width: 980px ) {
  .home-columns--small .home-columns__left,
.home-columns-extranet--small .home-columns__left {
    transform: none;
  }
  .home-columns--small .home-columns__left img,
.home-columns-extranet--small .home-columns__left img {
    margin-bottom: 0;
  }
}
@media screen and ( max-width: 980px ) {
  .home-columns--small .home-columns__right,
.home-columns-extranet--small .home-columns__right {
    margin-top: 0;
  }
}
.home-columns--small .home-columns__right .content,
.home-columns-extranet--small .home-columns__right .content {
  color: #050505;
  padding: 20px 0;
}
.home-columns--small .home-columns__right .button, .home-columns--small .home-columns__right .faq-box__button,
.home-columns-extranet--small .home-columns__right .button,
.home-columns-extranet--small .home-columns__right .faq-box__button {
  margin: 0;
  transform: none;
}
@media screen and ( max-width: 980px ) {
  .home-columns--small .home-columns__right .button, .home-columns--small .home-columns__right .faq-box__button,
.home-columns-extranet--small .home-columns__right .button,
.home-columns-extranet--small .home-columns__right .faq-box__button {
    margin: 0 auto;
  }
}
.home-columns__inner,
.home-columns-extranet__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 80px;
  transform: translateY(-90px);
}
@media screen and ( max-width: 980px ) {
  .home-columns__inner,
.home-columns-extranet__inner {
    flex-direction: column;
    padding-left: 0;
    transform: none;
  }
}
.home-columns__left,
.home-columns-extranet__left {
  max-width: 480px;
  width: 100%;
  color: #315562;
}
@media screen and ( max-width: 980px ) {
  .home-columns__left,
.home-columns-extranet__left {
    margin: 0 auto;
  }
  .home-columns__left .section-title,
.home-columns-extranet__left .section-title {
    text-align: center;
  }
  .home-columns__left .section-subtitle,
.home-columns-extranet__left .section-subtitle {
    text-align: center;
  }
  .home-columns__left .button, .home-columns__left .faq-box__button,
.home-columns-extranet__left .button,
.home-columns-extranet__left .faq-box__button {
    margin: 20px auto 0;
  }
}
.home-columns__left img,
.home-columns-extranet__left img {
  width: 100%;
  margin: 15px 0;
}
.home-columns__left .content,
.home-columns-extranet__left .content {
  padding-bottom: 20px;
}
@media screen and ( max-width: 980px ) {
  .home-columns__left .content,
.home-columns-extranet__left .content {
    padding: 0 20px;
  }
}
.home-columns__right,
.home-columns-extranet__right {
  max-width: 460px;
  width: 100%;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .home-columns__right,
.home-columns-extranet__right {
    max-width: 480px;
    margin: 40px auto 0;
  }
}
.home-columns__right--pink,
.home-columns-extranet__right--pink {
  background-color: #a5016f;
}
.home-columns__right--blue,
.home-columns-extranet__right--blue {
  background-color: #007d9d;
}
.home-columns__right .content,
.home-columns-extranet__right .content {
  padding: 20px 15px;
  color: #fff;
}
.home-columns__right .button, .home-columns__right .faq-box__button,
.home-columns-extranet__right .button,
.home-columns-extranet__right .faq-box__button {
  margin: 0 auto;
  border: 2px solid #f1f3f2;
  transform: translateY(50%);
}
@media screen and ( max-width: 980px ) {
  .home-columns__right .button, .home-columns__right .faq-box__button,
.home-columns-extranet__right .button,
.home-columns-extranet__right .faq-box__button {
    border: 0;
    background-color: #17272d;
  }
}
.home-columns__video,
.home-columns-extranet__video {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  height: 220px;
}
.home-columns__video svg,
.home-columns-extranet__video svg {
  width: 75px;
  height: 75px;
  fill: #fff;
}
.home-columns__header,
.home-columns-extranet__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px 15px 0;
}
.home-columns__header img,
.home-columns-extranet__header img {
  max-width: 60px;
}

.block-title {
  margin: 0 auto;
  padding: 60px 0 70px;
  background: #315562;
  color: #fff;
}
@media screen and (max-width: 700px ) {
  .block-title {
    padding: 40px 0;
  }
}
.block-title .section-title {
  text-align: center;
}
.block-title__content {
  color: inherit;
}
.block-title__content strong,
.block-title__content h1, .block-title__content h2, .block-title__content h3, .block-title__content h4, .block-title__content h5, .block-title__content h6 {
  color: #fff;
}
.block-title__content a {
  text-decoration: underline;
}

.block-image__desktop {
  max-width: 100%;
  width: 100%;
  display: block;
}
@media screen and ( max-width: 980px ) {
  .block-image__desktop {
    display: none;
  }
}
.block-image__mobile {
  max-width: 100%;
  width: 100%;
  display: none;
}
@media screen and ( max-width: 980px ) {
  .block-image__mobile {
    display: block;
  }
}

.block-text-image {
  display: flex;
  padding: 60px 0 30px;
}
@media screen and ( max-width: 980px ) {
  .block-text-image {
    flex-direction: column;
    padding: 40px 0 20px;
  }
}
@media screen and (max-width: 700px ) {
  .block-text-image {
    padding: 20px 0;
  }
}
.block-text-image__image {
  min-height: 400px;
  width: 50%;
  background-position: center;
  background-size: cover;
}
@media screen and ( max-width: 980px ) {
  .block-text-image__image {
    min-height: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 700px ) {
  .block-text-image__image {
    min-height: 250px;
  }
}
.block-text-image__image img {
  display: block;
  width: 100%;
}
.block-text-image__content {
  width: 50%;
}
@media screen and ( max-width: 980px ) {
  .block-text-image__content {
    width: 100%;
  }
}
@media screen and (max-width: 700px ) {
  .block-text-image__content {
    padding-bottom: 20px;
  }
}
.wrapper .block-text-image__content {
  width: 100%;
}
.block-text-image__content .half-wrapper {
  padding-left: 30px;
}
@media screen and ( max-width: 980px ) {
  .block-text-image__content .half-wrapper {
    padding: 30px 10px 0;
    max-width: 100%;
  }
}
.block-text-image__content .section-title {
  color: #315562;
}
.block-text-image__content .button, .block-text-image__content .faq-box__button {
  margin-top: 40px;
}
@media screen and (max-width: 700px ) {
  .block-text-image__content .button, .block-text-image__content .faq-box__button {
    margin: 15px auto;
  }
}
.block-text-image__content--left {
  display: flex;
  justify-content: flex-end;
}
.block-text-image__content--left .half-wrapper {
  padding-right: 30px;
  padding-left: 0;
}
@media screen and ( max-width: 980px ) {
  .block-text-image__content--left .half-wrapper {
    padding: 30px 10px 0;
  }
}

.block-video {
  position: relative;
  margin-top: 120px;
}
@media screen and (max-width: 700px ) {
  .block-video {
    margin-top: 60px;
  }
}
.block-video img {
  display: block;
  width: 100%;
}
.block-video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.block-video__button svg {
  width: 70px;
  height: 70px;
  fill: #fff;
}

.block-link {
  padding: 120px 0 30px;
}
@media screen and ( max-width: 980px ) {
  .block-link {
    padding-top: 80px;
  }
}
@media screen and (max-width: 700px ) {
  .block-link {
    padding-top: 40px;
  }
}
.block-link .section-title {
  text-align: center;
}
.block-link__nav {
  display: flex;
  justify-content: center;
  margin: 0 -10px;
  padding-top: 40px;
}
@media screen and ( max-width: 980px ) {
  .block-link__nav {
    margin: 0;
    flex-wrap: wrap;
  }
}
.block-link__item {
  max-width: 360px;
  width: 100%;
  margin: 0 10px;
}
@media screen and ( max-width: 980px ) {
  .block-link__item {
    margin: 10px;
  }
}
@media screen and (max-width: 700px ) {
  .block-link__item {
    margin: 10px 0;
  }
}
.block-link__item:hover {
  text-decoration: none;
}
.block-link__item:hover .block-link__item__thumb {
  opacity: 0.7;
}
.block-link__item__thumb {
  padding-top: 63.88%;
  background-size: cover;
  background-position: center;
  transition: opacity 200ms ease;
}
.block-link__item__title {
  max-width: 90%;
  padding-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 700px ) {
  .block-link__item__title {
    font-size: 1.4rem;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #bdc8cd;
  background-color: #fff;
  z-index: 6;
}
.header__inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 0;
  z-index: 8;
  transition: 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and ( max-width: 980px ) {
  .header__inner {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    align-items: stretch;
  }
}
.is-compact .header__inner {
  padding: 10px 0;
}
@media screen and ( max-width: 980px ) {
  .is-compact .header__inner {
    padding: 0;
  }
}
.header__logo {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .header__logo {
    padding: 15px 25px;
  }
}
@media screen and (max-width: 700px ) {
  .header__logo {
    padding: 10px;
    padding-right: 5px;
  }
}
.header__logo img {
  width: 80px;
}
@media screen and ( max-width: 980px ) {
  .header__logo img {
    width: 60px;
  }
}
@media screen and (max-width: 700px ) {
  .header__logo img {
    width: 40px;
  }
}
.header__logo__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 2px solid #f1f3f2;
}
@media screen and ( max-width: 980px ) {
  .header__logo__title {
    border-left: 0;
    margin-left: 0;
    padding-left: 15px;
  }
}
.header__logo__title div {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
}
@media screen and ( max-width: 980px ) {
  .header__logo__title div {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 700px ) {
  .header__logo__title div {
    font-size: 1.1rem;
  }
}
.header__logo__title span {
  font-weight: 300;
  font-style: italic;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .header__logo__title span {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 700px ) {
  .header__logo__title span {
    font-size: 1.2rem;
  }
}
.header__trigger {
  display: none;
  width: 100px;
  background-color: #315562;
}
@media screen and ( max-width: 980px ) {
  .header__trigger {
    display: block;
  }
}
@media screen and (max-width: 700px ) {
  .header__trigger {
    width: 65px;
  }
}
.header__trigger svg {
  fill: #fff;
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 700px ) {
  .header__trigger svg {
    width: 24px;
    height: 24px;
  }
}
.header__mobile {
  margin-left: auto;
  margin-bottom: auto;
}
@media screen and ( max-width: 980px ) {
  .header__mobile {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    transform: translate3d(-100%, 0, 0);
    transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
    overflow-y: auto;
    z-index: 6;
  }
  .header__mobile.is-visible {
    transform: none;
  }
}
.header__mobile__trigger {
  display: none;
  width: 100px;
  height: 100px;
  background-color: #315562;
  margin-left: auto;
  flex-shrink: 0;
}
@media screen and ( max-width: 980px ) {
  .header__mobile__trigger {
    display: block;
  }
}
@media screen and (max-width: 700px ) {
  .header__mobile__trigger {
    width: 65px;
    height: 65px;
  }
}
.header__mobile__trigger svg {
  fill: #fff;
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 700px ) {
  .header__mobile__trigger svg {
    width: 24px;
    height: 24px;
  }
}
.header__mobile__search {
  display: flex;
  align-items: center;
  padding: 0 15px 15px;
  color: #315562;
  font-weight: 600;
}
@media screen and ( max-width: 980px ) {
  .header__mobile__search {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .header__mobile__search {
    font-size: 1.6rem;
  }
}
.header__mobile__search:hover {
  text-decoration: none;
}
.header__mobile__actions {
  display: none;
}
@media screen and ( max-width: 980px ) {
  .header__mobile__actions {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
.header__nav {
  display: flex;
  transition: 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and ( max-width: 980px ) {
  .header__nav {
    flex-direction: column;
    padding: 15px;
  }
}
.is-compact .header__nav {
  transform: translateY(-150%);
}
@media screen and ( max-width: 980px ) {
  .is-compact .header__nav {
    transform: none;
  }
}
.header__nav__item {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  padding: 12px 0;
  color: #315562;
  font-weight: 600;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .header__nav__item {
    margin-right: 0;
    margin-top: 10px;
    padding: 12px;
    border-left: 4px solid;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .header__nav__item {
    font-size: 1.6rem;
  }
}
.header__nav__item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transform: translateY(8px);
  opacity: 0;
  transition: 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and ( max-width: 980px ) {
  .header__nav__item::before {
    display: none;
  }
}
.header__nav__item:hover {
  text-decoration: none;
}
.header__nav__item:hover::before {
  transform: none;
  opacity: 1;
}
.header__nav__item.is-active::before {
  transform: none;
  opacity: 1;
}
.header__nav__item--student {
  border-color: #95b700;
}
.header__nav__item--student::before {
  background-color: #95b700;
}
.header__nav__item--student.is-active {
  color: #95b700;
}
.header__nav__item--center {
  border-color: #a5016f;
}
.header__nav__item--center::before {
  background-color: #a5016f;
}
.header__nav__item--center.is-active {
  color: #a5016f;
}
.header__nav__item--partner {
  border-color: #007d9d;
}
.header__nav__item--partner::before {
  background-color: #007d9d;
}
.header__nav__item--partner.is-active {
  color: #007d9d;
}
.header__nav__item svg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  fill: currentColor;
}
.header__nav__item.is-active {
  border-color: currentColor;
}
.header__actions {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(50%);
  transition: 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and ( max-width: 980px ) {
  .header__actions {
    display: none;
  }
}
.is-compact .header__actions {
  transform: translateY(-50%);
}
.header__actions__search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #bdc8cd;
}
@media screen and ( max-width: 980px ) {
  .header__actions__search {
    height: 42px;
    width: 42px;
    margin-right: 15px;
  }
}
.header__actions__search svg {
  width: 16px;
  height: 22px;
  fill: #315562;
}
.header__actions__menu.is-open {
  background-color: #a5016f;
}
.header__actions__menu, .header__extranet__actions__menu {
  display: flex;
  align-items: center;
  background-color: #007d9d;
  color: #fff;
  font-weight: bold;
}
.header__actions__menu.is-open .header__actions__menu--close,
.header__actions__menu.is-open .header__extranet__actions__menu--close, .header__extranet__actions__menu.is-open .header__actions__menu--close,
.header__extranet__actions__menu.is-open .header__extranet__actions__menu--close {
  display: flex;
}
.header__actions__menu.is-open .header__actions__menu--open,
.header__actions__menu.is-open .header__extranet__actions__menu--open, .header__extranet__actions__menu.is-open .header__actions__menu--open,
.header__extranet__actions__menu.is-open .header__extranet__actions__menu--open {
  display: none;
}
.header__actions__menu svg, .header__extranet__actions__menu svg {
  fill: #fff;
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
.header__actions__menu--open, .header__actions__menu--close, .header__extranet__actions__menu--open, .header__extranet__actions__menu--close {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.header__actions__menu--close, .header__extranet__actions__menu--close {
  display: none;
}

.footer,
.footer__extranet {
  padding-top: 40px;
  color: #fff;
  font-size: 1.2rem;
}
@media screen and ( max-width: 980px ) {
  .footer,
.footer__extranet {
    padding-top: 0;
  }
}
.footer__partners,
.footer__extranet__partners {
  padding: 45px 0 30px;
  background-color: #f1f3f2;
  color: #050505;
}
@media screen and ( max-width: 980px ) {
  .footer__partners,
.footer__extranet__partners {
    padding: 30px 0;
  }
  .footer__partners .section-title,
.footer__extranet__partners .section-title {
    text-align: center;
    color: #315562;
  }
}
.footer__partners__slider,
.footer__extranet__partners__slider {
  display: flex;
  align-items: center;
  padding: 40px 40px 0;
}
@media screen and ( max-width: 980px ) {
  .footer__partners__slider,
.footer__extranet__partners__slider {
    padding: 30px 30px 0;
  }
}
.footer__partners__track,
.footer__extranet__partners__track {
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
}
.footer__partners__nav,
.footer__extranet__partners__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-color: #315562;
}
@media screen and ( max-width: 980px ) {
  .footer__partners__nav,
.footer__extranet__partners__nav {
    display: none;
  }
}
.footer__partners__nav svg,
.footer__extranet__partners__nav svg {
  height: 16px;
  width: 8px;
  fill: #fff;
}
.footer__partner,
.footer__extranet__partner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 80px;
  background-color: #fff;
  padding: 5px;
}
.footer__partner img,
.footer__extranet__partner img {
  max-height: 100%;
  width: auto;
  max-width: 160px;
}
.footer__newsletter,
.footer__extranet__newsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .footer__newsletter,
.footer__extranet__newsletter {
    flex-direction: column;
  }
}
.footer__newsletter.is-valid .footer__newsletter__inner,
.footer__extranet__newsletter.is-valid .footer__newsletter__inner {
  display: none;
}
.footer__newsletter.is-valid .footer__newsletter__confirmation,
.footer__extranet__newsletter.is-valid .footer__newsletter__confirmation {
  display: block;
}
.footer__newsletter--1,
.footer__extranet__newsletter--1 {
  background-color: #95b700;
}
.footer__newsletter--2,
.footer__extranet__newsletter--2 {
  background-color: #a5016f;
}
.footer__newsletter--3,
.footer__extranet__newsletter--3 {
  background-color: #007d9d;
}
.footer__newsletter__title,
.footer__extranet__newsletter__title {
  padding-right: 20px;
  font-style: italic;
  font-weight: 600;
  font-size: 1.8rem;
}
@media screen and (max-width: 700px ) {
  .footer__newsletter__title,
.footer__extranet__newsletter__title {
    font-size: 1.6rem;
  }
}
.footer__newsletter__inner,
.footer__extranet__newsletter__inner {
  display: flex;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .footer__newsletter__inner,
.footer__extranet__newsletter__inner {
    flex-direction: column;
  }
}
.footer__newsletter__confirmation,
.footer__extranet__newsletter__confirmation {
  display: none;
  font-style: italic;
  font-weight: 600;
  font-size: 1.8rem;
}
@media screen and (max-width: 700px ) {
  .footer__newsletter__confirmation,
.footer__extranet__newsletter__confirmation {
    font-size: 1.6rem;
  }
}
.footer__main,
.footer__extranet__main {
  padding: 50px 0;
  background-color: #315562;
}
@media screen and ( max-width: 980px ) {
  .footer__main,
.footer__extranet__main {
    display: none;
  }
}
.footer__main__inner,
.footer__extranet__main__inner {
  display: flex;
}
.footer__main .column,
.footer__extranet__main .column {
  width: 100%;
}
.footer__main .column__title,
.footer__extranet__main .column__title {
  font-weight: 600;
}
.footer__main .column__title::after,
.footer__extranet__main .column__title::after {
  content: "";
  display: block;
  height: 1px;
  width: 20px;
  margin: 15px 0;
  background-color: #fff;
}
.footer__main .column a,
.footer__extranet__main .column a {
  display: block;
  margin-top: 5px;
}
.footer__secondary,
.footer__extranet__secondary {
  padding: 15px 0;
  background-color: #17272d;
}
@media screen and ( max-width: 980px ) {
  .footer__secondary .footer__copyright,
.footer__extranet__secondary .footer__copyright {
    padding-top: 20px;
    font-size: 0.8rem;
  }
}
.footer__secondary__inner,
.footer__extranet__secondary__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .footer__secondary__inner,
.footer__extranet__secondary__inner {
    flex-direction: column-reverse;
  }
}
@media screen and ( max-width: 980px ) {
  .footer__secondary__nav,
.footer__extranet__secondary__nav {
    display: none;
  }
}
.footer__secondary__nav a,
.footer__extranet__secondary__nav a {
  margin: 0 10px;
}
.footer__bottom,
.footer__extranet__bottom {
  background-color: #17272d;
  border-top: 2px solid #939a9d;
}
@media screen and ( max-width: 980px ) {
  .footer__bottom,
.footer__extranet__bottom {
    display: none;
  }
}
.footer__bottom__inner,
.footer__extranet__bottom__inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
}
.footer__bottom__content,
.footer__extranet__bottom__content {
  max-width: 460px;
  width: 100%;
  text-align: right;
  padding-right: 15px;
}

.footer__main__inner {
  justify-content: space-between;
}
.footer__main .column {
  max-width: 150px;
}

.footer__extranet__main__inner {
  position: relative;
}
.footer__extranet__main .column {
  margin-right: 110px;
  max-width: 160px;
}
.footer__extranet__main .column:last-child {
  position: absolute;
  right: 0;
  padding-right: 25px;
  margin-right: 0;
}

@media screen and (max-width: 700px ) {
  [data-page=home] .page__content {
    flex-grow: 0;
  }
}

.home {
  flex-grow: 1;
  margin-top: 70px;
}
@media screen and ( max-width: 980px ) {
  .home {
    margin-top: 0;
  }
}
.home__intro {
  display: flex;
  height: 100%;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .home__intro {
    height: auto;
    align-items: flex-start;
  }
}
.home__nav {
  display: flex;
  justify-content: space-between;
}
@media screen and ( max-width: 980px ) {
  .home__nav {
    flex-direction: column;
  }
}
.home__nav .item {
  display: block;
  max-width: 380px;
  width: 100%;
  background-size: cover;
  background-position: center;
  text-align: center;
  overflow: hidden;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item {
    max-width: 100%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 700px ) {
  .home__nav .item {
    margin-top: 15px;
  }
}
.home__nav .item:hover {
  text-decoration: none;
}
.home__nav .item:hover .item__content {
  transform: none;
  transition: 450ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.home__nav .item:last-child {
  transform: translateX(-1px);
}
.home__nav .item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 30px 30px;
  color: #fff;
  transform: translateY(100%) translateY(-120px);
  transition: 150ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__content {
    padding: 30px;
    transform: none;
  }
}
.home__nav .item__content--1 {
  background-color: #95b700;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__content--1 {
    background-color: rgba(149, 183, 0, 0.8);
  }
}
.home__nav .item__content--2 {
  background-color: #a5016f;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__content--2 {
    background-color: rgba(165, 1, 111, 0.8);
  }
}
.home__nav .item__content--3 {
  background-color: #007d9d;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__content--3 {
    background-color: rgba(0, 125, 157, 0.8);
  }
}
.home__nav .item__title {
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__title {
    line-height: 1.4;
  }
}
.home__nav .item__profile {
  padding-top: 20px;
  line-height: 1;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__profile {
    line-height: 1.4;
  }
}
@media screen and (max-width: 700px ) {
  .home__nav .item__profile {
    padding-top: 10px;
  }
}
.home__nav .item__desc {
  padding: 50px 0;
  line-height: 2;
  font-size: 1.2rem;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item__desc {
    padding-top: 25px;
  }
}
@media screen and (max-width: 700px ) {
  .home__nav .item__desc {
    display: none;
  }
}
.home__nav .item .button, .home__nav .item .faq-box__button {
  align-self: center;
  margin-top: auto;
}
@media screen and ( max-width: 980px ) {
  .home__nav .item .button, .home__nav .item .faq-box__button {
    display: none;
  }
}
.home__footer {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  align-items: flex-end;
}
@media screen and ( max-width: 980px ) {
  .home__footer {
    padding-top: 20px;
    justify-content: flex-end;
  }
}
@media screen and ( max-width: 980px ) {
  .home__footer__nav {
    display: none;
  }
}
.home__footer__nav a {
  margin-right: 15px;
  text-decoration: underline;
  opacity: 0.4;
  font-size: 1.2rem;
}
.home__footer__nav a:hover {
  opacity: 1;
}
.home__footer__logo {
  display: flex;
  align-items: center;
}
.home__footer__logo a {
  margin-right: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.home__footer__logo img {
  max-width: 70px;
}
.home__hero {
  display: none;
  background-size: cover;
  background-position: center;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .home__hero {
    display: flex;
  }
}
.home__hero__inner {
  width: 100%;
  padding: 60px 0;
  background-color: rgba(5, 5, 5, 0.8);
  text-align: center;
}
.home__hero .section-title {
  padding-bottom: 10px;
}
@media screen and ( max-width: 980px ) {
  .home__hero .section-title {
    color: inherit !important;
  }
}

.home-video__title {
  padding: 40px 0;
  text-align: center;
  color: #315562;
}
.home-video__thumb {
  position: relative;
  padding-top: 31.2%;
  background-position: center;
  background-size: cover;
}
@media screen and ( max-width: 980px ) {
  .home-video__thumb {
    max-width: calc(100% - 20px);
    width: 100%;
    margin: 0 auto;
    padding-top: 46.67%;
  }
}
.home-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.home-video__play svg {
  width: 60px;
  height: 60px;
  fill: #fff;
}

.home-zoom {
  margin-top: 75px;
  padding: 40px 0;
  background-color: #315562;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .home-zoom {
    margin-top: 40px;
    padding-bottom: 0;
  }
}
.home-zoom__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-zoom__infos {
  max-width: 480px;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .home-zoom__infos {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .home-zoom__infos .button, .home-zoom__infos .faq-box__button {
    order: 2;
    margin: 0 auto;
    transform: translateY(50%);
    background-color: #17272d;
    color: #fff;
  }
}
.home-zoom__infos img {
  display: block;
  margin-top: 20px;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .home-zoom__infos img {
    order: 1;
    margin-top: 0;
  }
}
.home-zoom__infos__content {
  padding: 20px 0;
  color: inherit;
}
@media screen and ( max-width: 980px ) {
  .home-zoom__infos__content {
    order: 0;
  }
}
.home-zoom__quote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 55px;
  text-align: center;
}
@media screen and ( max-width: 980px ) {
  .home-zoom__quote {
    display: none;
  }
}
.home-zoom__quote__content {
  max-width: 290px;
}
.home-zoom__quote__content blockquote {
  margin-top: 10px;
  font-style: italic;
  font-size: 2.2rem;
}
.home-zoom__quote__profile {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-zoom__quote__profile p {
  margin-top: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.home-zoom__quote__picture {
  height: 160px;
  width: 160px;
  border-radius: 80px;
  background-position: center;
  background-size: cover;
}

.home-student__intro {
  display: flex;
  color: #fff;
  background-color: #95b700;
}
@media screen and ( max-width: 980px ) {
  .home-student__intro {
    background-color: transparent;
    flex-direction: column;
  }
}
.home-student__intro__image {
  max-width: 50%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
.home-student__intro .half-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 110px 0;
}
@media screen and ( max-width: 980px ) {
  .home-student__intro .half-wrapper {
    background-color: #95b700;
    padding: 35px 50px;
    max-width: calc(100% - 20px);
    width: 100%;
    margin: -30px auto 0;
    justify-content: center;
  }
}
@media screen and (max-width: 700px ) {
  .home-student__intro .half-wrapper {
    padding: 35px 10px;
  }
}
.home-student__intro__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-student__intro__content .divider {
  padding: 10px 0;
  align-self: flex-start;
  text-transform: uppercase;
}
@media screen and ( max-width: 980px ) {
  .home-student__intro__content .divider {
    align-self: auto;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.home-student__intro__content .button, .home-student__intro__content .faq-box__button {
  align-self: flex-start;
  background-color: #596d00;
  font-size: 1.2rem;
}
@media screen and ( max-width: 980px ) {
  .home-student__intro__content .button, .home-student__intro__content .faq-box__button {
    align-self: auto;
    text-align: center;
  }
}
.home-student__intro__title {
  font-weight: bold;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .home-student__intro__title {
    text-align: center;
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px ) {
  .home-student__intro__title {
    font-size: 1.8rem;
  }
}
.home-student__intro .location {
  padding-top: 20px;
}

.home-center__intro {
  padding: 40px 0;
  background-color: #a5016f;
  color: #fff;
}
@media screen and ( max-width: 980px ) {
  .home-center__intro {
    flex-direction: column;
    padding-top: 0;
  }
}
.home-center__intro__inner {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .home-center__intro__inner {
    flex-direction: column;
  }
}
.home-center__intro__image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  max-width: 50%;
  width: 100%;
  height: 245px;
}
@media screen and ( max-width: 980px ) {
  .home-center__intro__image {
    max-width: 100%;
  }
}
.home-center__intro__image svg {
  width: 70px;
  height: 70px;
  fill: #fff;
}
.home-center__intro__main {
  padding: 30px 70px 0 0;
}
@media screen and ( max-width: 980px ) {
  .home-center__intro__main {
    padding-right: 0;
    padding-bottom: 20px;
    text-align: center;
  }
}
.home-center__intro .content {
  padding: 25px 0;
  color: inherit;
}
.home-center__intro .button, .home-center__intro .faq-box__button {
  color: #a5016f;
}
@media screen and ( max-width: 980px ) {
  .home-center__intro .button, .home-center__intro .faq-box__button {
    margin: 0 auto;
  }
}

.home-map {
  padding: 40px 0;
}
.home-map__inner {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .home-map__inner {
    flex-direction: column;
  }
}
.home-map__inner img {
  max-width: 50%;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .home-map__inner img {
    max-width: 100%;
  }
}
.home-map__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  width: 100%;
  padding-left: 40px;
}
@media screen and ( max-width: 980px ) {
  .home-map__form {
    align-items: center;
    max-width: 100%;
    padding-left: 0;
  }
}
.home-map__form .divider {
  padding: 10px 0;
  text-transform: uppercase;
}
@media screen and ( max-width: 980px ) {
  .home-map__form .divider {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.home-map__form .button, .home-map__form .faq-box__button {
  text-align: center;
  font-size: 1.2rem;
}
.home-map__form__title {
  max-width: 250px;
  line-height: 1.2;
  font-weight: bold;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .home-map__form__title {
    max-width: 100%;
    text-align: center;
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px ) {
  .home-map__form__title {
    font-size: 1.8rem;
  }
}
.home-map__form .location {
  padding-top: 20px;
}

.home-split {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .home-split {
    flex-direction: column;
  }
}
.home-split__half {
  max-width: 50%;
  width: 100%;
}
@media screen and ( max-width: 980px ) {
  .home-split__half {
    max-width: 100%;
    padding: 0 10px;
  }
}
.home-split__half--left {
  display: flex;
  justify-content: flex-end;
  background-color: #f1f3f2;
}
@media screen and ( max-width: 980px ) {
  .home-split__half--left {
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.home-split__half--left .half-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-split__half--primary {
  background-color: #315562;
}
.home-split__half--grey {
  background-color: #f1f3f2;
}
.home-split__half:last-child .half-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and ( max-width: 980px ) {
  .home-split__half:last-child .half-wrapper {
    max-width: 100%;
  }
}
.home-split__half:last-child .half-wrapper--wrap {
  flex-wrap: wrap;
}

.home-ideas {
  margin-bottom: 40px;
}
.home-ideas .section-title {
  color: #a5016f;
}
.home-ideas__content {
  padding: 20px 20px 0 0;
  font-style: italic;
}
@media screen and ( max-width: 980px ) {
  .home-ideas__content {
    font-size: 1.4rem;
  }
}
.home-ideas__image {
  padding: 70px 0;
  background-color: #a5016f;
}
@media screen and ( max-width: 980px ) {
  .home-ideas__image {
    padding: 30px 0;
  }
}
@media screen and (max-width: 700px ) {
  .home-ideas__image {
    padding: 15px 0;
  }
}

.home-partner__intro .content {
  padding: 20px 20px 0 0;
}
@media screen and ( max-width: 980px ) {
  .home-partner__intro .content {
    padding: 20px 0;
  }
}
.home-partner__intro .section-title {
  color: #007d9d;
}
@media screen and ( max-width: 980px ) {
  .home-partner__intro .section-title {
    padding-top: 20px;
  }
}
.home-partner__intro__image {
  padding: 50px 0 20px;
  background-color: #007d9d;
}
@media screen and ( max-width: 980px ) {
  .home-partner__intro__image {
    padding-top: 20px;
  }
}
.home-partner__intro__image .half-wrapper {
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.home-partner__intro__image img {
  margin: 20px 0 10px;
}
.home-partner__intro__title {
  font-weight: bold;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .home-partner__intro__title {
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px ) {
  .home-partner__intro__title {
    font-size: 1.8rem;
  }
}

.find__loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
  z-index: 6;
}
.is-loading .find__loader {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}
.find__loader__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  transform: translate3d(0, 100px, 0);
  opacity: 0;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.is-loading .find__loader__inner {
  opacity: 1;
  transform: none;
}
.find__loader__inner img {
  animation: spin 800ms linear infinite;
  width: 35px;
  height: 35px;
}
.find__search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #315562;
  padding: 15px 10px;
}
@media screen and (max-width: 700px ) {
  .find__search {
    padding: 25px 10px;
  }
}
.find__search__title {
  font-weight: bold;
  font-style: italic;
  color: #fff;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .find__search__title {
    font-size: 1.8rem;
  }
}
.find__search__form {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
@media screen and (max-width: 700px ) {
  .find__search__form {
    padding-top: 10px;
    padding-left: 0;
    width: 100%;
  }
}
.find__search__form input {
  flex-shrink: 0;
  min-width: 320px;
  padding: 15px;
  background-color: #fff;
  color: #315562;
  font: inherit;
  font-weight: 600;
  font-size: 1.2rem;
}
@media screen and (max-width: 700px ) {
  .find__search__form input {
    min-width: auto;
    width: 100%;
    padding: 10px 15px;
  }
}
.find .button, .find .faq-box__button {
  margin: 20px auto 0;
}
.find .button[disabled], .find [disabled].faq-box__button {
  opacity: 0.5;
}
.find__map {
  position: relative;
  background-color: beige;
  overflow: hidden;
}
.find__map__inner {
  height: 520px;
  z-index: 1;
}
.find__popup {
  max-width: 360px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-40%);
  opacity: 0;
  pointer-events: none;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
  z-index: 2;
}
@media screen and (max-width: 700px ) {
  .find__popup {
    left: 0;
    top: 0;
    padding: 10px;
  }
}
.find__popup.is-visible {
  pointer-events: all;
  opacity: 1;
  transform: translateY(-50%);
}
@media screen and (max-width: 700px ) {
  .find__popup.is-visible {
    transform: none;
  }
}
.find__popup__back {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #315562;
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}
.find__popup .result {
  margin: 10px 0 0;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
@media screen and ( max-width: 980px ) {
  .find__popup .result {
    max-width: 360px;
  }
}
@media screen and (max-width: 700px ) {
  .find__popup .result {
    opacity: 0.8;
  }
}
.find__popup .result__section {
  padding-left: 0;
}
.find__filters {
  height: 80px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px ) {
  .find__filters {
    padding: 15px 10px;
    height: auto;
  }
}
.find__filters__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 700px ) {
  .find__filters__inner {
    justify-content: center;
  }
}
.find__filters__main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.find__filters__reset {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
@media screen and (max-width: 700px ) {
  .find__filters__reset {
    margin-left: 0;
    margin-top: 15px;
  }
}
.find__filters__reset svg {
  width: 1.4em;
  height: 1.4em;
  fill: #315562;
  margin-left: 8px;
}
.find__filters__results {
  display: none;
  text-transform: uppercase;
  color: #315562;
  font-weight: 600;
  font-size: 1.2rem;
}
@media screen and (max-width: 700px ) {
  .find__filters__results {
    padding-top: 15px;
  }
}
.find__filter {
  display: flex;
  align-items: center;
}
.find__filter__label {
  font-weight: bold;
  flex-shrink: 0;
  padding-right: 10px;
  font-size: 1.2rem;
}
.find__filter select {
  width: 100%;
  min-width: 170px;
  padding: 15px;
  background-color: #315562;
  border-radius: 0;
  border: 0;
  font: inherit;
  line-height: 1;
  color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: 600;
  font-size: 1.2rem;
}
.find__filter select:focus, .find__filter select:active {
  color: #fff !important;
}
.find__filter select:-moz-focusring {
  color: #fff;
  text-shadow: 0 0 0 #000;
}
.find__filter select::-ms-expand {
  display: none;
}
.find__filter select option {
  background-color: #fff;
  color: #315562;
}
.find__results {
  position: relative;
  padding: 40px 0;
  background-color: #f1f3f2;
}
@media screen and (max-width: 700px ) {
  .find__results {
    padding: 20px 0;
  }
}
.find__results__inner {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.find__results__search-success, .find__results__empty {
  width: 100%;
  text-align: center;
  padding: 0 10px 0 20px;
  font-weight: 600;
  color: #315562;
  font-size: 1.4rem;
}

.result {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  margin: 10px;
  padding: 20px 24px;
  background-color: #fff;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.006);
}
@supports (display: grid) {
  .result {
    opacity: 0;
    animation: slide-up 250ms ease-out forwards;
  }
}
@media screen and ( max-width: 980px ) {
  .result {
    max-width: calc(50% - 20px);
  }
}
@media screen and (max-width: 700px ) {
  .result {
    padding: 15px 20px;
    max-width: 100%;
  }
}
.result__header {
  display: flex;
  align-items: center;
}
.result__logo {
  width: 30px;
}
.result__badge {
  margin: 0 8px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  font-style: italic;
  color: #fff;
  line-height: 1;
  font-size: 1.2rem;
}
.result__badge--1 {
  background-color: #a5016f;
}
.result__badge--2 {
  background-color: #800080;
}
.result__badge--3 {
  background-color: #007d9d;
}
.result__badge--4 {
  background-color: #612661;
}
.result__content {
  flex-grow: 1;
}
.result__section {
  display: flex;
  padding: 30px 0 0 60px;
  font-size: 1.3rem;
}
@media screen and (max-width: 700px ) {
  .result__section {
    padding: 15px 0 0;
  }
}
.result__section svg {
  width: 20px;
  height: 20px;
  fill: #315562;
  flex-shrink: 0;
}
.result__section__infos {
  padding-left: 20px;
}
.result__section__infos span {
  text-transform: uppercase;
  display: block;
}
.result__name {
  padding-top: 25px;
  color: #315562;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .result__name {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .result__name {
    padding-top: 15px;
    font-size: 1.6rem;
  }
}
.result__telephone {
  display: flex;
  font-size: 1.3rem;
}
.result__telephone svg {
  width: 16px;
  fill: #315562;
}
.result__organism {
  font-size: 1.3rem;
}
.result__organism span {
  display: flex;
  font-size: 1.6rem;
}
.result__organism svg {
  width: 20px;
  fill: #315562;
}
.result__footer {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  text-decoration: underline;
  padding-top: 40px;
}

.news {
  padding: 35px 0 100px 0px;
}
.news .button, .news .faq-box__button {
  background-color: #007d9d;
  color: #fff;
  font-weight: 600;
}
.news__inner {
  display: flex;
  align-items: flex-start;
  padding-top: 35px;
}
@media screen and ( max-width: 980px ) {
  .news__inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.news__image {
  max-width: 545px;
  width: 100%;
  flex-shrink: 0;
}
@media screen and ( max-width: 980px ) {
  .news__image {
    margin-bottom: 10px;
  }
}
.news__main {
  padding-left: 30px;
  font-family: "Open Sans", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
@media screen and ( max-width: 980px ) {
  .news__main {
    padding: 40px 0 0 0;
  }
}
.news__title {
  font-weight: bold;
  text-transform: uppercase;
  color: #315562;
  line-height: 26px;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .news__title {
    text-align: center;
  }
}
@media screen and (max-width: 700px ) {
  .news__title {
    font-size: 18px;
  }
}
.news__date {
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
}
@media screen and ( max-width: 980px ) {
  .news__date {
    text-align: center;
  }
}
.news__content {
  padding-top: 12px;
  font-weight: 600;
}

.news-all {
  padding-top: 40px;
}
.news-all .section-title {
  text-align: center;
  padding-bottom: 20px;
}
.news-all__items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media screen and ( max-width: 980px ) {
  .news-all__items {
    justify-content: center;
  }
}
.news-all__items .news-cards__item {
  padding-bottom: 60px;
  margin: 0 10px;
}
.news-all .button--primary {
  margin: 0 auto;
}
@media screen and ( max-width: 980px ) {
  .news-all .button--primary {
    margin-bottom: 30px;
  }
}

.documents {
  background-color: #f1f3f2;
  padding-bottom: 30px;
}
.documents .section-title {
  color: #f1f3f2;
  background-color: #315562;
  text-align: center;
  padding: 30px;
}
.documents .wrapper {
  max-width: 930px;
}
.documents .wrapper.is-end .button, .documents .wrapper.is-end .faq-box__button {
  display: none;
}
.documents__header {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
}
@media screen and (max-width: 700px ) {
  .documents__header {
    flex-direction: column;
    padding: 15px 0;
  }
}
.documents__filters {
  display: flex;
}
@media screen and (max-width: 700px ) {
  .documents__filters {
    flex-wrap: wrap;
  }
}
.documents__filters span {
  font-weight: bold;
  color: #050505;
}
@media screen and (max-width: 700px ) {
  .documents__filters span {
    width: 100%;
  }
}
@media screen and (max-width: 700px ) {
  .documents__filters .radio {
    max-width: 50%;
    width: 100%;
  }
}
.documents__filters .radio label {
  margin-left: 20px;
}
@media screen and (max-width: 700px ) {
  .documents__filters .radio label {
    margin-left: 0;
    margin-top: 5px;
  }
}
@media screen and (max-width: 700px ) {
  .documents__results {
    padding-top: 15px;
  }
}
.documents__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
@media screen and ( max-width: 980px ) {
  .documents__inner {
    justify-content: center;
  }
}
@media screen and (max-width: 700px ) {
  .documents__inner {
    margin: 0;
  }
}
.documents__inner::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background-image: linear-gradient(to top, #f1f3f2, transparent);
  pointer-events: none;
}
.is-end .documents__inner::after {
  display: none;
}
.documents .button, .documents .faq-box__button {
  margin: 30px auto 0;
  text-transform: initial;
}

.document {
  display: flex;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  margin: 15px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.17);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
@supports (display: grid) {
  .document {
    opacity: 0;
    animation: slide-up 250ms ease-out forwards;
  }
}
@media screen and (max-width: 700px ) {
  .document {
    display: flex;
    max-width: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    margin: 8px 0;
  }
}
.document__icon {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 700px ) {
  .document__icon {
    max-width: 100px;
  }
}
.document__icon--youtube {
  background-color: #a5016f;
}
.document__icon--audio {
  background-color: #95b700;
}
.document__icon--pdf {
  background-color: #007d9d;
}
.document__icon--image {
  background-color: #315562;
}
.document svg {
  fill: #fff;
  height: 55px;
}
@media screen and (max-width: 700px ) {
  .document svg {
    height: 40px;
  }
}
.document__info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  min-height: 130px;
  padding: 15px;
}
@media screen and (max-width: 700px ) {
  .document__info {
    width: 100%;
    min-height: auto;
  }
}
.document__title {
  font-weight: bold;
  color: #315562;
  line-height: 20px;
  font-size: 1.6rem;
}
.document__footer {
  display: flex;
  margin-top: auto;
  padding-top: 5px;
  width: 100%;
  font-size: 1.2rem;
  color: #315562;
  line-height: 16px;
  justify-content: space-between;
}
.document__button {
  background-color: #007d9d;
  font-weight: bold;
  margin: 0 auto;
  color: #f1f3f2;
}

.select {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  font: inherit;
  line-height: 1;
  color: #050505;
  font-weight: bold;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.select::-ms-expand {
  display: none;
}

.search__form {
  padding: 60px 0 120px;
  background-color: #315562;
}
@media screen and ( max-width: 980px ) {
  .search__form {
    padding: 40px 0 80px;
  }
}
.search__form__inner {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
.search__form__heading {
  text-align: center;
  font-weight: 600;
  font-style: italic;
  color: #fff;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .search__form__heading {
    font-size: 1.8rem;
  }
}
.search__form form {
  display: flex;
  padding-top: 30px;
}
@media screen and ( max-width: 980px ) {
  .search__form form {
    padding-top: 15px;
  }
}
.search__form input[type=search] {
  max-width: 325px;
  width: 100%;
  flex-grow: 1;
  height: 40px;
  padding: 0 20px;
  background-color: #fff;
  font-size: 1.2rem;
}
.search__form input[type=search]:-ms-input-placeholder {
  color: #8e8e8e;
  font-style: normal;
}
.search__form input[type=search]::-webkit-input-placeholder {
  color: #8e8e8e;
  font-style: normal;
}
.search__form input[type=search]::placeholder {
  color: #8e8e8e;
  opacity: 1;
  font-style: normal;
}
.search__form input[type=search]:focus:-ms-input-placeholder {
  color: #c1c1c1;
}
.search__form input[type=search]:focus::-webkit-input-placeholder {
  color: #c1c1c1;
}
.search__form input[type=search]:focus::placeholder {
  color: #c1c1c1;
}
.search__form input[type=submit] {
  display: block;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  color: #007d9d;
  font-weight: bold;
  cursor: pointer;
}
.search__wrapper {
  background-color: #f1f3f2;
}
.search__empty {
  max-width: 610px;
  padding-top: 50px;
  padding-bottom: 50px;
  color: #315562;
  font-weight: 600;
  font-style: italic;
  text-align: center;
  font-size: 2rem;
}
.search__header {
  padding: 30px 0;
}
@media screen and ( max-width: 980px ) {
  .search__header {
    padding: 15px 0;
  }
}
.search__number {
  font-weight: 600;
  font-style: italic;
  color: #315562;
  font-size: 2rem;
}
@media screen and ( max-width: 980px ) {
  .search__number {
    font-size: 1.4rem;
  }
}
.search__tabs {
  padding-top: 30px;
}
@media screen and ( max-width: 980px ) {
  .search__tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 0;
  }
}
.search__tabs button {
  padding: 10px 25px;
  margin-right: 60px;
  border-bottom: 1px solid #315562;
  font-size: 1.2rem;
}
@media screen and ( max-width: 980px ) {
  .search__tabs button {
    max-width: 48%;
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
}
.search__tabs button.is-active {
  font-weight: bold;
  border-bottom: 4px solid #315562;
}
.search__results__panel {
  display: none;
  padding-bottom: 40px;
}
.search__results__panel.is-active {
  display: block;
}
.search__results__panel .button, .search__results__panel .faq-box__button {
  margin: 40px auto 0;
}
.search__results__locations {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.search__results__documents {
  display: flex;
  flex-wrap: wrap;
  max-width: 950px;
}
.search__main {
  position: relative;
}
.search__loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
  z-index: 6;
}
.is-loading .search__loader {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}
.search__loader__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #fff;
  transform: translate3d(0, 100px, 0);
  opacity: 0;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1);
}
.is-loading .search__loader__inner {
  opacity: 1;
  transform: none;
}
.search__loader__inner img {
  animation: spin 800ms linear infinite;
  width: 35px;
  height: 35px;
}
.search__result {
  padding: 30px;
  border-bottom: 1px solid #000;
  opacity: 0;
  animation: slide-up 250ms ease-out forwards;
}
.search__result:last-child {
  border: none;
}
.search__result__title {
  font-weight: 600;
  font-size: 1.6rem;
}
.search__result__content {
  padding-top: 15px;
  font-size: 1.2rem;
}

.contact {
  background-color: #f1f3f2;
}
.contact .section-title {
  color: #f1f3f2;
  background-color: #315562;
  text-align: center;
  padding: 30px;
}
.contact__inner {
  max-width: 740px;
  padding: 30px 0 60px;
}
.contact form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: opacity 200ms ease;
}
.contact form.is-loading {
  pointer-events: none;
  opacity: 0.4;
}
.contact__field {
  max-width: 100%;
  width: 100%;
  padding-top: 15px;
}
.contact__field--half {
  max-width: 49%;
}
@media screen and ( max-width: 980px ) {
  .contact__field--half {
    max-width: 100%;
  }
}
.contact__field input,
.contact__field textarea {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-family: inherit;
  padding: 12px 10px;
  line-height: 1;
  font-size: 1.4rem;
}
.contact__field textarea {
  min-height: 140px;
  line-height: 1.6;
  resize: vertical;
}
.contact__field label {
  padding-left: 15px;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .contact__field label {
    padding-left: 0;
  }
}
.contact__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 40px;
}
@media screen and ( max-width: 980px ) {
  .contact__footer {
    display: block;
    padding-top: 10px;
  }
  .contact__footer button[type=submit] {
    margin: 15px auto 0;
  }
}
.contact__checkboxes {
  max-width: 460px;
}
.contact__checkboxes .checkbox {
  margin-top: 10px;
}
.contact__checkboxes .checkbox label {
  display: inline-block;
  font-size: 1.2rem;
}
.contact__checkboxes .checkbox label::before {
  display: inline-block;
  margin-top: 0.2em;
}
.contact__checkboxes .checkbox label a {
  color: #007d9d;
}
.contact__message {
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid transparent;
  font-weight: bold;
  font-size: 1.2rem;
}
.contact__message--error {
  color: #a5016f;
  border-color: #a5016f;
  background-color: rgba(165, 1, 111, 0.15);
}
.contact__message--success {
  color: #007d9d;
  border-color: #007d9d;
  background-color: rgba(0, 125, 157, 0.1);
}

.content-page .section-title {
  color: #f1f3f2;
  background-color: #315562;
  text-align: center;
  padding: 30px;
}
.content-page__content {
  padding-top: 40px;
}
@media screen and (max-width: 700px ) {
  .content-page__content {
    padding-top: 25px;
  }
}

.flash-success {
  display: none;
}

.page__extranet .header__inner {
  justify-content: space-between;
}
@media screen and ( max-width: 980px ) {
  .page__extranet .header__logo {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
@media screen and (max-width: 700px ) {
  .page__extranet .header__logo {
    padding: 7px;
  }
}
@media screen and ( max-width: 980px ) {
  .page__extranet .header__logo a {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
@media screen and (max-width: 700px ) {
  .page__extranet .header__logo a {
    width: 45px;
  }
}
@media screen and ( max-width: 980px ) {
  .page__extranet .header__logo img {
    width: 51.7px;
  }
}
@media screen and (max-width: 700px ) {
  .page__extranet .header__logo img {
    width: 37px;
  }
}
@media screen and (max-width: 700px ) {
  .page__extranet .header__logo__title {
    padding-left: 10px;
  }
}
.page__extranet .header__logo__extranet {
  margin-top: 5px;
}
.page__extranet .header__logo__extranet .button, .page__extranet .header__logo__extranet .faq-box__button {
  padding: 7px;
  font-size: 1rem;
}
@media screen and (max-width: 700px ) {
  .page__extranet .header__logo__extranet .button, .page__extranet .header__logo__extranet .faq-box__button {
    padding: 3px;
    font-size: 0.8rem;
  }
}

.header__logo__subtitle__button {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
@media screen and ( max-width: 980px ) {
  .header__logo__subtitle__button {
    display: none;
  }
}
.header__logo__subtitle__button .button, .header__logo__subtitle__button .faq-box__button {
  padding: 8px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
}
.header__extranet__actions {
  padding-top: 22px;
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .header__extranet__actions {
    padding-top: 0;
  }
}
.header__extranet__actions--unic {
  padding: 10px 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.header__extranet__actions--unic svg {
  fill: #fff;
  height: 18px;
  width: 18px;
  margin-right: 7px;
}
.header__extranet__actions--stat, .header__extranet__actions__menu {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  z-index: 6;
  font-size: 1.782rem;
}
@media screen and ( max-width: 980px ) {
  .header__extranet__actions--stat, .header__extranet__actions__menu {
    padding: 0;
  }
  .header__extranet__actions--stat span, .header__extranet__actions__menu span {
    display: none;
  }
  .header__extranet__actions--stat svg, .header__extranet__actions__menu svg {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 700px ) {
  .header__extranet__actions--stat svg, .header__extranet__actions__menu svg {
    width: 24px;
    height: 24px;
  }
}
.header__extranet__actions--stat {
  margin-right: 16px;
}
@media screen and ( max-width: 980px ) {
  .header__extranet__actions--stat {
    display: none;
    width: 100px;
    justify-content: center;
  }
}
@media screen and (max-width: 700px ) {
  .header__extranet__actions--stat {
    width: 65px;
    margin-right: 10px;
  }
}
.header__extranet__actions--stat svg {
  margin-left: 7px;
  transform: rotate(-90deg);
}
@media screen and ( max-width: 980px ) {
  .header__extranet__actions--stat svg {
    margin-left: 0;
  }
}
.header__extranet__actions__menu {
  line-height: 1;
}
.header__extranet__actions__menu--open, .header__extranet__actions__menu--close {
  padding: 0;
  width: 140px;
  justify-content: center;
}
@media screen and ( max-width: 980px ) {
  .header__extranet__actions__menu--open, .header__extranet__actions__menu--close {
    width: 100px;
  }
}
@media screen and (max-width: 700px ) {
  .header__extranet__actions__menu--open, .header__extranet__actions__menu--close {
    width: 65px;
  }
}

.page__extranet .footer {
  padding-top: 70px;
}

[data-extranet-form] {
  display: none;
}

.block__medium-color, .account__block__main, .extranet-page__title {
  background-color: #f1f3f2;
}

.page__extranet .footer {
  padding-top: 0;
}
.page__extranet .page__content {
  padding-bottom: 70px;
}
.page__extranet--small .page__content {
  padding-bottom: 0;
}

.extranet-page__title {
  padding: 55px 0 65px;
  line-height: 1;
  color: #315562;
}
@media screen and ( max-width: 980px ) {
  .extranet-page__title {
    padding: 40px 45px;
  }
}
@media screen and (max-width: 700px ) {
  .extranet-page__title {
    padding: 35px 40px;
  }
}
.extranet-page__title--margin {
  margin-bottom: 55px;
}

.private span {
  display: flex;
  align-items: center;
}
.private span:after {
  content: "";
  flex: 0 0 auto;
  display: block;
  margin-left: 20px;
  width: 19px;
  height: 25px;
  background-image: url("/web/img/lock.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.private--reversed span:after {
  order: -1;
  margin-left: 0;
  margin-right: 10px;
}

.triangle {
  position: relative;
}
.triangle:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateY(-50%);
  right: 0;
  top: 50%;
  border-style: solid;
  border-width: 11.5px 0 11.5px 19px;
  border-color: transparent transparent transparent #fff;
  transition: 0.2s;
}
.triangle--blue:after {
  border-color: transparent transparent transparent #007d9d;
}

[data-accordion-trigger] {
  cursor: pointer;
}

.no-desktop {
  display: none;
}
@media screen and ( max-width: 980px ) {
  .no-desktop {
    display: block;
  }
  .no-desktop--stat {
    display: flex;
    align-items: center;
  }
  .no-desktop--stat svg {
    margin-left: 0;
    margin-right: 10px;
    width: 21px;
    height: 17px;
    transform: rotate(-90deg);
  }
}

.login {
  margin-top: 60px;
  display: flex;
  min-height: 440px;
}
@media screen and (max-width: 700px ) {
  .login {
    flex-wrap: wrap;
  }
}
.login.wrapper {
  max-width: 780px;
}
.login__title {
  font-weight: bold;
  line-height: 1.285;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.login__welcome {
  width: 360px;
  text-align: center;
  background: #315562;
  flex: 0 0 auto;
}
@media screen and ( max-width: 980px ) {
  .login__welcome {
    width: 45%;
  }
}
@media screen and (max-width: 700px ) {
  .login__welcome {
    width: 100%;
  }
}
.login__welcome__image {
  height: 345px;
  background-image: url("/web/img/extranet.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 700px ) {
  .login__welcome__image {
    display: none;
  }
}
.login__welcome__title, .login__welcome__subtitle {
  color: #fff;
  padding: 0 10px;
}
.login__welcome__title {
  margin-top: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.3;
  font-size: 1.8rem;
}
.login__welcome__subtitle {
  margin-top: 8px;
  line-height: 1.285;
  font-size: 1.639rem;
}
@media screen and ( max-width: 980px ) {
  .login__welcome__subtitle {
    margin-bottom: 20px;
  }
}
.login__text {
  padding: 40px 25px 0;
  background: #f1f3f2;
  color: #315562;
  line-height: 1.285;
  flex: 1;
  font-size: 1.4rem;
}
.login__form {
  margin-top: 55px;
  padding-bottom: 25px;
}
@media screen and (max-width: 700px ) {
  .login__form {
    margin-top: 30px;
  }
}
.login__form--small {
  margin-top: 40px;
}
.login__form__lost-pasword {
  margin-top: 5px;
}
.login__form__submit {
  display: block;
  margin-left: auto;
  font-weight: bold;
  margin-top: 8px;
}
.login__form__submit--bottom {
  margin-top: 35px;
}
.login__form__error {
  margin-bottom: 10px;
  color: rgba(255, 0, 0, 0.75);
}
.login__form__back {
  margin-top: 85px;
  display: block;
}
.login .extranet__form__input {
  margin-bottom: 18px;
}
.login .extranet__form__input--small {
  margin-bottom: 12px;
}

.extranet__form__input, .extranet__form__textarea {
  background: white;
  color: #315562;
  padding: 15px;
  width: 100%;
  border: 1px solid #dfe2e1;
  line-height: 1;
  font-weight: bold;
  font-size: 1.8rem;
}
.extranet__form__input:-ms-input-placeholder, .extranet__form__textarea:-ms-input-placeholder {
  color: #979897;
  font-style: normal;
}
.extranet__form__input::-webkit-input-placeholder, .extranet__form__textarea::-webkit-input-placeholder {
  color: #979897;
  font-style: normal;
}
.extranet__form__input::placeholder, .extranet__form__textarea::placeholder {
  color: #979897;
  opacity: 1;
  font-style: normal;
}
.extranet__form__input:focus:-ms-input-placeholder, .extranet__form__textarea:focus:-ms-input-placeholder {
  color: #cacbca;
}
.extranet__form__input:focus::-webkit-input-placeholder, .extranet__form__textarea:focus::-webkit-input-placeholder {
  color: #cacbca;
}
.extranet__form__input:focus::placeholder, .extranet__form__textarea:focus::placeholder {
  color: #cacbca;
}
.extranet__form__input--small, .extranet__form__textarea--small {
  padding: 11px;
  font-size: 1.4rem;
}
.extranet__form__error {
  color: rgba(255, 0, 0, 0.75);
  font-style: italic;
  font-weight: bold;
  padding-bottom: 5px;
  font-size: 1.4rem;
}

.account__block {
  margin-bottom: 70px;
}
.account__block--longer {
  margin-bottom: 80px;
}
@media screen and ( max-width: 980px ) {
  .account__block--longer {
    margin-bottom: 60px;
  }
}
.account__block .section-title {
  color: #315562;
}
.account__block__title {
  margin-bottom: 35px;
}
.account__block__main {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and ( max-width: 980px ) {
  .account__block__main {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 700px ) {
  .account__block__main {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.account__block__main--title {
  padding-top: 40px;
  padding-bottom: 70px;
}
@media screen and ( max-width: 980px ) {
  .account__block__main--title {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}
.account__block__main--title--disabled.section-title {
  color: #6a6a6a;
}
.account__block__main--title--disabled.section-title span:before {
  background-color: #6a6a6a;
}
.account__block__main__title {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}
@media screen and (max-width: 700px ) {
  .account__block__main__title {
    margin-bottom: 25px;
  }
}
.account__block__main--no-title {
  padding-top: 75px;
  padding-bottom: 150px;
}
@media screen and ( max-width: 980px ) {
  .account__block__main--no-title {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
.account__block__columns {
  display: flex;
  justify-content: space-between;
}
@media screen and ( max-width: 980px ) {
  .account__block__columns {
    flex-wrap: wrap;
  }
}
.account__block__columns form {
  width: 48.214286%;
}
@media screen and ( max-width: 980px ) {
  .account__block__columns form {
    width: 100%;
  }
  .account__block__columns form:not(:last-child) {
    margin-bottom: 60px;
  }
}
.account__infos {
  display: flex;
  font-weight: bold;
  color: #315562;
  justify-content: space-between;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .account__infos {
    flex-wrap: wrap;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 700px ) {
  .account__infos {
    font-size: 1.5rem;
  }
}
@media screen and ( max-width: 980px ) {
  .account__infos__column {
    width: 100% !important;
  }
}
.account__infos__column--small {
  width: 43.26923077%;
}
.account__infos__column--med {
  width: 46.15384615%;
}
.account__infos__column--large {
  width: 50%;
}
.account__infos__column--disabled, .account__infos__column--disabled input {
  color: #6a6a6a;
}
.account__infos__line {
  display: flex;
}
@media screen and ( max-width: 980px ) {
  .account__infos__line {
    margin-bottom: 17px;
  }
}
.account__infos__line:not(:last-child) {
  margin-bottom: 17px;
}
.account__infos__line--center {
  align-items: center;
}
.account__infos__line--disabled {
  color: #6a6a6a;
}
.account__infos__label {
  width: 160px;
  flex: 0 0 auto;
}
@media screen and ( max-width: 980px ) {
  .account__infos__label {
    width: 25% !important;
  }
}
@media screen and (max-width: 700px ) {
  .account__infos__label {
    width: 40% !important;
  }
}
.account__infos__label--med {
  width: 150px;
}
.account__infos__label--small {
  width: 140px;
}
.account__infos__input {
  width: 100%;
}
.account__infos__input ul {
  display: none;
}
.account__infos__input--error ul {
  display: block;
}
.account__infos__input--error input {
  border-color: rgba(255, 0, 0, 0.75);
}
.account__infos__value {
  padding: 10px 0;
}
.account__actions {
  text-align: center;
  position: absolute;
  top: calc(100% - 20px);
  left: 0;
  right: 0;
}
.account__actions .button, .account__actions .faq-box__button {
  font-weight: 600;
  display: inline-block;
  font-size: 1.4rem;
}
.account__actions .button.active, .account__actions .active.faq-box__button {
  background-color: #95b700;
}
.account__actions__cancel, .account__actions__edit {
  cursor: pointer;
}
.account__actions__cancel {
  text-decoration: underline;
  color: #315562;
  margin-top: 13px;
  font-weight: 600;
  display: block;
  margin: 13px auto 0;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .account__actions__cancel {
    font-size: 1.3rem;
  }
}
.account__actions__cancel:hover {
  text-decoration: none;
}
.account__actions .account__actions__cancel--edited,
.account__actions .account__actions__edited {
  display: none;
}
.account__actions.account__actions--edited > .account__actions__cancel--edited {
  display: block !important;
}
.account__actions.account__actions--edited > .account__actions__edited {
  display: inline-block !important;
}
.account__actions.account__actions--edited > .account__actions__edit,
.account__actions.account__actions--edited > .account__actions__cancel--edition {
  display: none !important;
}
.account__trigger {
  display: none;
}
.account__trigger + .account__block__main .account__infos__value--dyn,
.account__trigger + .account__block__main .account__actions__edit {
  display: inline-block;
}
.account__trigger + .account__block__main .account__infos__input,
.account__trigger + .account__block__main .account__infos__line--edit,
.account__trigger + .account__block__main .account__actions__cancel--edition,
.account__trigger + .account__block__main .account__actions__submit {
  display: none;
}
.account__trigger:checked + .account__block__main .account__infos__value--dyn,
.account__trigger:checked + .account__block__main .account__actions__edit {
  display: none;
}
.account__trigger:checked + .account__block__main .account__infos__line input,
.account__trigger:checked + .account__block__main .account__actions__cancel--edition,
.account__trigger:checked + .account__block__main .account__infos__input {
  display: block;
}
.account__trigger:checked + .account__block__main .account__infos__line--edit {
  display: flex;
}
.account__trigger:checked + .account__block__main .account__actions__submit {
  display: inline-block;
}

@media screen and ( max-width: 980px ) {
  .home-infos .home-split__half--left {
    padding-left: 3%;
    padding-right: 3%;
  }
}
.home-infos .home-split__half--left .half-wrapper {
  justify-content: flex-start;
  padding: 0 3%;
}
@media screen and ( max-width: 980px ) {
  .home-infos .home-split__half--left .half-wrapper {
    padding: 0;
    max-width: none;
  }
}
@media screen and ( max-width: 980px ) {
  .home-infos__welcome {
    order: -1;
  }
}
@media screen and ( max-width: 980px ) {
  .home-infos {
    order: -1;
  }
}

.news-extranet {
  margin-top: 60px;
}
@media screen and ( max-width: 980px ) {
  .news-extranet {
    margin-top: 40px;
  }
}
@media screen and (max-width: 700px ) {
  .news-extranet {
    margin-top: 20px;
  }
}

.notifications__title {
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .notifications__title {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}
.notifications__title svg {
  margin-right: 20px;
  height: 54px;
  width: 75px;
}
@media screen and ( max-width: 980px ) {
  .notifications__title svg {
    width: 50px;
    height: 36px;
  }
}
.notifications__list__item {
  display: flex;
  padding-right: 11.7%;
}
.notifications__list__item:not(:only-child) {
  margin-bottom: 18px;
}
@media screen and ( max-width: 980px ) {
  .notifications__list__item {
    padding-right: 0;
    justify-content: space-between;
  }
}
.notifications__list__item__text, .notifications__list__item__link {
  color: white;
  font-weight: 600;
  font-size: 1.4rem;
}
.notifications__list__item__text {
  width: 63.3%;
}
@media screen and ( max-width: 980px ) {
  .notifications__list__item__text {
    padding-right: 3%;
  }
}
.notifications__list__item__link {
  text-decoration: underline;
}
.notifications__list__item__link:hover {
  text-decoration: none;
}

.welcome {
  padding-left: 80px;
  margin-top: 60px;
  margin-bottom: 50px;
  display: block !important;
}
@media screen and ( max-width: 980px ) {
  .welcome {
    margin-top: 40px;
    padding: 0;
  }
}
.welcome__title, .welcome__text {
  color: #315562;
  font-size: 1.4rem;
}
.welcome__title em, .welcome__text em {
  font-style: italic;
}
.welcome__title {
  font-weight: 600;
  margin-bottom: 20px;
}
.welcome__text {
  margin-bottom: 20px;
}
.welcome__button {
  font-weight: 600;
}

.faq-box {
  background-color: #007d9d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-box--home {
  min-height: 400px;
}
@media screen and ( max-width: 980px ) {
  .faq-box--home {
    min-height: auto;
    padding: 20px 0 20px;
  }
}
.faq-box--home .faq-box__inner {
  text-align: center;
}
.faq-box--home .faq-box__title {
  font-weight: 600;
  font-style: italic;
  margin-bottom: 33px;
}
@media screen and ( max-width: 980px ) {
  .faq-box--home .faq-box__title {
    margin-bottom: 15px;
  }
}
.faq-box--home .faq-box__img {
  max-width: 211px;
  width: 200%;
}
.faq-box--insert {
  padding-top: 30px;
  padding-bottom: 25px;
  overflow: hidden;
}
.faq-box--insert .faq-box__img {
  float: right;
}
.faq-box--insert .faq-box__title {
  margin-bottom: 30px;
}
.faq-box__title {
  color: white;
  text-transform: uppercase;
  font-size: 2rem;
}
.faq-box__title--big {
  font-size: 2.2rem;
}
.faq-box__button {
  font-weight: 600;
  color: #007d9d;
}
@media screen and ( max-width: 980px ) {
  .faq-box__button {
    background-color: #fff !important;
    transform: none !important;
  }
}
@media screen and ( max-width: 980px ) {
  .faq-box__img {
    margin-bottom: 15px;
  }
}

.extranet-slider__title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.2rem;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider__title {
    text-align: center;
  }
}
.extranet-slider__title--top {
  color: #a5016f;
  margin-bottom: 20px;
  transform: translateY(-90px);
  padding-left: 80px;
  padding-left: 0;
  transform: none;
}
.extranet-slider__title--in {
  color: #fff;
  margin-bottom: 18px;
}
.extranet-slider__inner {
  background-color: #a5016f;
  padding: 80px 0 20px 0;
  color: white;
  overflow: hidden;
  position: relative;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider__inner {
    padding: 40px 0;
  }
}
.extranet-slider__content {
  color: white;
  margin-bottom: 60px;
  font-size: 1.4rem;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider__content {
    margin-bottom: 30px;
  }
}
.extranet-slider__button {
  margin-top: auto;
  text-align: right;
}
.extranet-slider__button .button, .extranet-slider__button .faq-box__button {
  display: inline-block;
}
.extranet-slider__item {
  display: flex;
  flex-direction: column;
  padding: 0 35px 0 30px;
  height: auto;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider__item {
    padding: 0 20px;
  }
}
.extranet-slider__nav {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 30px;
  right: 35px;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider {
    display: none;
  }
}
.extranet-slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-color: #fff;
}
.extranet-slider__item svg {
  height: 16px;
  width: 8px;
  fill: #a5016f;
}
.extranet-slider__navigation {
  display: none;
}
@media screen and ( max-width: 980px ) {
  .extranet-slider__navigation {
    display: block;
  }
}

.home-find {
  background-color: #a5016f;
  margin-top: 60px;
}
@media screen and (max-width: 700px ) {
  .home-find {
    text-align: center;
  }
}
.home-find .section-title {
  color: white;
}
.home-find .wrapper {
  background-image: url("/web/img/map.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;
}
@media screen and ( max-width: 980px ) {
  .home-find .wrapper {
    background-image: none;
  }
}
.home-find__title {
  margin-bottom: 45px;
  padding-top: 45px;
}
@media screen and ( max-width: 980px ) {
  .home-find__title {
    margin-bottom: 25px;
    padding-top: 25px;
  }
}
.home-find__content {
  display: flex;
  align-content: center;
  padding-bottom: 70px;
}
@media screen and ( max-width: 980px ) {
  .home-find__content {
    justify-content: space-between;
  }
}
@media screen and (max-width: 700px ) {
  .home-find__content {
    flex-direction: column;
    padding-bottom: 40px;
  }
}
.home-find__else {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 9px;
}
@media screen and ( max-width: 980px ) {
  .home-find__else {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 700px ) {
  .home-find__else {
    padding: 15px 0;
  }
}
.home-find__link {
  font-weight: 600;
  color: #a5016f;
}
@media screen and (max-width: 700px ) {
  .home-find__link {
    display: block;
  }
}
.home-find__form {
  min-width: 35.714286%;
}
@media screen and ( max-width: 980px ) {
  .home-find__form {
    width: 50%;
  }
}
@media screen and (max-width: 700px ) {
  .home-find__form {
    width: 100%;
  }
}

.home-documents {
  margin-top: 75px;
}
.home-documents__title {
  color: #315562;
}
.home-documents__inner {
  margin-top: 40px;
}
.home-documents__footer {
  text-align: center;
  margin-top: 50px;
}
@media screen and ( max-width: 980px ) {
  .home-documents__footer {
    margin-top: 30px;
  }
}
@media mobil {
  .home-documents__footer {
    margin-top: 20px;
  }
}
.home-documents__footer .button, .home-documents__footer .faq-box__button {
  display: inline-block;
  font-weight: 600;
  padding: 15px 70px;
  line-height: 1.857;
}
@media screen and ( max-width: 980px ) {
  .home-documents__footer .button, .home-documents__footer .faq-box__button {
    padding: 10px 40px;
  }
}
@media screen and (max-width: 700px ) {
  .home-documents__footer .button, .home-documents__footer .faq-box__button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and ( max-width: 980px ) {
  .extra-documents .wrapper {
    padding-right: 2%;
    padding-left: 2%;
  }
  .extra-documents .triangle:after {
    right: 2%;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents .wrapper {
    padding-right: 1%;
    padding-left: 1%;
  }
  .extra-documents .triangle:after {
    right: 1%;
  }
}
.extra-documents__category__title, .extra-documents__sub-category__title {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__category__title, .extra-documents__sub-category__title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents__category__title, .extra-documents__sub-category__title {
    font-size: 1.6rem;
  }
}
.extra-documents__category__title {
  color: #fff;
  background-color: #315562;
  border: 1px solid #bdc8cd;
  padding: 48px 0 37px;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__category__title {
    padding: 25px 0 18px;
  }
}
.extra-documents__category.is-open .triangle:after {
  transform: translateY(-50%) rotate(90deg);
}
.extra-documents__sub-category__title {
  background-color: #f0f0f0;
  color: #315562;
  padding: 32px 0 28px;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__sub-category__title {
    padding: 20px 0 14px;
  }
}
.extra-documents__item {
  font-size: 1.6rem;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__item {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents__item {
    font-size: 1.2rem;
  }
}
.extra-documents__item--reduce {
  background-color: #315562;
  color: #fff;
  border-top: 1px solid #f0f0f0;
}
.extra-documents__item--reduce:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.extra-documents__item--reduce svg {
  fill: #fff;
}
.extra-documents__item--reduce .extra-documents__item__name {
  width: 35.714286%;
}
.extra-documents__item--reduce .extra-documents__item__category {
  width: 25%;
}
.extra-documents__item--reduce .extra-documents__item__update {
  text-align: center;
}
.extra-documents__item--reduce:hover {
  border-top: 1px solid #315562;
}
.extra-documents__item--reduce:hover:last-child {
  border-bottom: 1px solid #315562;
}
.extra-documents__item--reduce:hover .extra-documents__category__title--private span:after {
  background-image: url("/web/img/lock-green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.extra-documents__item--large, .extra-documents__item--reduce:hover {
  transition: 0.3s;
  background-color: #fff;
  color: #315562;
  border-top: 1px solid #315562;
}
.extra-documents__item--large:last-child, .extra-documents__item--reduce:hover:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.extra-documents__item--large svg, .extra-documents__item--reduce:hover svg {
  fill: #315562;
}
.extra-documents__item--large .extra-documents__item__update {
  margin-right: 100px;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__item--large .extra-documents__item__update {
    margin-right: 20px;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents__item--large .extra-documents__item__update {
    margin-right: 10px;
  }
}
.extra-documents__item__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 95px;
  cursor: pointer;
  padding: 10px 0;
}
.extra-documents__item__name {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__item__name {
    width: 57%;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents__item__name {
    width: 60%;
  }
}
.extra-documents__item__name svg {
  margin-right: 25px;
  width: 43px;
  flex: 0 0 auto;
}
@media screen and ( max-width: 980px ) {
  .extra-documents__item__name svg {
    margin-right: 20px;
  }
}
@media screen and (max-width: 700px ) {
  .extra-documents__item__name svg {
    margin-right: 15px;
    width: 35px;
  }
}
.extra-documents__item__infos {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px ) {
  .extra-documents__item__infos {
    width: 35%;
    justify-content: space-between;
  }
}
.extra-documents__item__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  background-color: #315562;
}
@media screen and (max-width: 700px ) {
  .extra-documents__item__link {
    flex: 0 0 auto;
  }
}
.extra-documents__item__link svg {
  height: 16px;
  width: 8px;
  fill: #fff;
}

.faq-bannier {
  background-color: #007d9d;
  padding: 25px 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.faq-bannier__columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-bannier .faq-box__img {
  max-width: 200px;
  margin-left: 190px;
}
@media screen and (max-width: 700px ) {
  .faq-bannier .faq-box__img {
    display: none;
  }
}
.faq-bannier .faq-box__title {
  font-weight: bold;
  margin-bottom: 30px;
}
.faq-bannier .button, .faq-bannier .faq-box__button {
  display: block;
  text-align: center;
}

[data-page=extra_faq] .modal__close {
  display: none;
}

.faq-page__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and ( max-width: 980px ) {
  .faq-page__inner {
    align-items: start;
  }
}
@media screen and (max-width: 700px ) {
  .faq-page__inner {
    flex-direction: column;
  }
}
.faq-page__column {
  display: flex;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .faq-page__column {
    flex-direction: column;
    align-items: start;
  }
}
.faq-page__title {
  color: #007d9d;
  width: 270px;
}
.faq-page__button {
  margin-left: 4.017857%;
  width: 250px;
}
@media screen and ( max-width: 980px ) {
  .faq-page__button {
    margin-left: 0;
    margin-top: 30px;
  }
}
.faq-page__form {
  margin-left: auto;
  position: relative;
}
@media screen and (max-width: 700px ) {
  .faq-page__form {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
}
.faq-page__form form {
  display: flex;
  width: 100%;
  align-items: center;
}
@media screen and ( max-width: 980px ) {
  .faq-page__form form {
    justify-content: center;
  }
}
.faq-page__form input[type=search] {
  background-color: #fff;
}
.faq-page__form input[type=submit] {
  background-color: #007d9d;
  color: #fff;
}
.faq-page__form input[type=search] {
  max-width: 325px;
  width: 100%;
  flex-grow: 1;
  height: 38px;
  border: 1px solid #e7e7e7;
  padding: 0 10px;
  font-size: 1.2rem;
  max-width: none;
}
.faq-page__form input[type=search]:-ms-input-placeholder {
  color: #bec0c1;
  font-style: italic;
}
.faq-page__form input[type=search]::-webkit-input-placeholder {
  color: #bec0c1;
  font-style: italic;
}
.faq-page__form input[type=search]::placeholder {
  color: #bec0c1;
  opacity: 1;
  font-style: italic;
}
.faq-page__form input[type=search]:focus:-ms-input-placeholder {
  color: #bec0c1;
}
.faq-page__form input[type=search]:focus::-webkit-input-placeholder {
  color: #bec0c1;
}
.faq-page__form input[type=search]:focus::placeholder {
  color: #bec0c1;
}
.faq-page__form input[type=submit] {
  display: block;
  height: calc( 38px + 2px);
  width: 48px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.4rem;
}
.faq-page__form__reset {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: bold;
  position: absolute;
  text-transform: none;
  top: calc(38px + 15px);
  right: 0;
  color: #007d9d;
  font-size: 1.2rem;
}
@media screen and (max-width: 700px ) {
  .faq-page__form__reset {
    margin-left: 0;
    margin-top: 15px;
  }
}
.faq-page__form__reset svg {
  width: 1.4em;
  height: 1.4em;
  fill: #007d9d;
  margin-left: 8px;
}
.faq-page__actions {
  padding: 55px 0;
}
.faq-page__actions__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3%;
}
@media screen and (max-width: 700px ) {
  .faq-page__actions__inner {
    flex-wrap: wrap;
    padding: 0;
  }
}
.faq-page__actions .button, .faq-page__actions .faq-box__button {
  width: 48.660714%;
  min-width: 300px;
  height: 110px;
  flex: 0 0 auto;
}
@media screen and (max-width: 700px ) {
  .faq-page__actions .button, .faq-page__actions .faq-box__button {
    width: 100%;
    height: 90px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.faq-page__actions .button:first-child, .faq-page__actions .faq-box__button:first-child {
  line-height: 110px;
}
@media screen and (max-width: 700px ) {
  .faq-page__actions .button:first-child, .faq-page__actions .faq-box__button:first-child {
    line-height: 90px;
  }
}
.faq-page__actions .button:not(:first-child), .faq-page__actions .faq-box__button:not(:first-child) {
  margin-left: 45px;
}
@media screen and (max-width: 700px ) {
  .faq-page__actions .button:not(:first-child), .faq-page__actions .faq-box__button:not(:first-child) {
    margin-left: 0;
    margin-top: 30px;
  }
}
.faq-page__actions .button--question::before {
  left: 12%;
}
@media screen and ( max-width: 980px ) {
  .faq-page__actions .button--question::before {
    left: 8%;
  }
}
.faq-page__actions__doc {
  display: flex;
  text-align: center;
  align-items: center;
}
.faq-page__actions__doc::before {
  content: "";
  height: 50px;
  width: 42px;
  position: relative;
  left: 1%;
  flex: 0 0 auto;
  background-image: url("/web/img/icon-document.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and ( max-width: 980px ) {
  .faq-page__actions__doc::before {
    left: 0;
  }
}

.faq .is-open .triangle:after {
  transform: translateY(-50%) rotate(90deg);
}
.faq__category {
  background-color: #007d9d;
  color: #fff;
  padding: 35px 0;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .faq__category {
    padding: 25px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 700px ) {
  .faq__category {
    font-size: 1.4rem;
  }
}
.faq__category--followed {
  background-color: #f0f0f0;
  color: #007d9d;
}
.faq__category--followed span {
  display: flex;
  align-items: center;
}
.faq__category--followed span svg {
  width: 27px;
  height: 39px;
  stroke: #007d9d;
  fill: #fff;
  margin-left: 30px;
}
.faq__item {
  padding: 50px 30px;
  font-size: 1.4rem;
}
.faq__item:not(:last-child) {
  border-bottom: 1px solid #007d9d;
}
@media screen and (max-width: 700px ) {
  .faq__item {
    padding: 30px 20px;
  }
}
.faq__item__inner {
  display: flex;
}
.faq__item__column {
  max-width: 960px;
  padding-right: 20px;
  width: 85.714286%;
}
.faq__item__content {
  line-height: 1.2;
  margin-bottom: 50px;
  font-size: 1.8rem;
}
@media screen and ( max-width: 980px ) {
  .faq__item__content {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 700px ) {
  .faq__item__content {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }
}
.faq__item__infos {
  font-style: italic;
  margin-bottom: 10px;
}
.faq__item__status {
  text-decoration: underline;
  font-weight: 600;
}
.faq__answer {
  background-color: #f0f0f0;
  padding: 25px 5.803571% 50px;
  color: #007d9d;
  margin-top: 25px;
}
.faq__answer__admin {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 25px;
  font-size: 1.4rem;
}
.faq__answer__admin::before {
  content: "";
  margin-right: 14px;
  width: 36px;
  padding-bottom: 36px;
  background-image: url("/web/img/icon-profil.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.faq__answer__content {
  line-height: 1.2;
  margin-bottom: 25px;
  font-size: 1.6rem;
}
.faq__answer__data {
  text-align: right;
  font-style: italic;
  font-size: 1.4rem;
}
.faq__btrack {
  margin-left: auto;
  text-transform: uppercase;
  color: #007d9d;
  text-align: right;
}
.faq__btrack svg {
  height: 53px;
  width: 38px;
  margin-bottom: 5px;
  display: block;
  margin-left: auto;
}
.faq__btrack--unfollow svg {
  fill: #007d9d;
  stroke: transparent;
}
.faq__btrack--follow {
  stroke: #007d9d;
  fill: transparent;
}
.faq__no-result {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 1.6rem;
}

.faq-ask {
  display: flex;
  position: relative;
}
@media screen and (max-width: 700px ) {
  .faq-ask {
    flex-wrap: wrap;
  }
}
.faq-ask__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
@media screen and (max-width: 700px ) {
  .faq-ask__close {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.faq-ask__close svg {
  fill: white;
  height: 12px;
  width: 12px;
  margin-left: 4px;
}
.faq-ask__column {
  padding: 100px 0 30px;
  position: relative;
}
@media screen and (max-width: 700px ) {
  .faq-ask__column {
    width: 100% !important;
    padding: 60px 0 20px;
  }
}
.faq-ask__title {
  position: absolute;
  background-color: #007d9d;
  top: 0;
  left: 0;
  right: 0;
  padding: 13px 25px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
@media screen and ( max-width: 980px ) {
  .faq-ask__title {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 700px ) {
  .faq-ask__title {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.2rem;
  }
}
.faq-ask__title::before {
  content: "";
  display: block;
  width: 12px;
  height: 20px;
  margin-right: 12px;
  background-image: url("/web/img/question-mark.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.faq-ask__infos {
  width: 38.596491%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
}
.faq-ask__infos__content {
  color: #315562;
  line-height: 1.285;
  font-size: 1.4rem;
}
.faq-ask__form {
  flex: 1;
  padding-left: 35px;
  padding-right: 35px;
  background-color: #f1f3f2;
}
@media screen and (max-width: 700px ) {
  .faq-ask__form {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
  }
}
.faq-ask__form__row:not(:first-child) {
  margin-top: 30px;
}
.faq-ask__form .triangle:after {
  right: 25px;
  transform: translateY(-50%) rotate(90deg) scale(0.8);
}
.faq-ask__form select {
  width: 100%;
  padding: 18px 20px;
  background-color: #007d9d;
  border-radius: 0;
  border: 0;
  font: inherit;
  line-height: 1;
  color: #fff;
  -moz-appearance: none;
  text-transform: uppercase;
  -webkit-appearance: none;
  font-weight: bold;
  font-size: 1.4rem;
}
.faq-ask__form select:focus, .faq-ask__form select:active {
  color: #fff !important;
  background-color: #007d9d;
}
.faq-ask__form select:-moz-focusring {
  color: #fff;
  text-shadow: 0 0 0 #000;
  background-color: #007d9d;
}
.faq-ask__form select option {
  background-color: #fff;
  color: #007d9d;
}
.faq-ask__form__textarea label {
  cursor: pointer;
  display: block;
  background-color: #007d9d;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  padding: 18px 20px;
  font-size: 1.4rem;
}
.faq-ask__form__textarea textarea {
  display: block;
  resize: none;
  width: 100%;
  min-height: 130px;
  padding: 30px 25px;
  border: 1px solid #e0e3e2;
  font-family: "Open Sans", apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.4rem;
}
.faq-ask__form__textarea textarea:-ms-input-placeholder {
  color: #939593;
  font-style: normal;
}
.faq-ask__form__textarea textarea::-webkit-input-placeholder {
  color: #939593;
  font-style: normal;
}
.faq-ask__form__textarea textarea::placeholder {
  color: #939593;
  opacity: 1;
  font-style: normal;
}
.faq-ask__form__textarea textarea:focus:-ms-input-placeholder {
  color: #c6c8c6;
}
.faq-ask__form__textarea textarea:focus::-webkit-input-placeholder {
  color: #c6c8c6;
}
.faq-ask__form__textarea textarea:focus::placeholder {
  color: #c6c8c6;
}
.faq-ask__form__required {
  color: #939593;
  text-align: right;
  padding-right: 20px;
  margin-bottom: 30px;
  font-size: 1.1rem;
}
.faq-ask__form__button {
  font-weight: 600;
  text-align: right;
}
.faq-ask__form__button .button, .faq-ask__form__button .faq-box__button {
  display: inline-block;
}