.contact
  background-color: $medium-color

  .section-title
    color: $medium-color
    background-color: $primary-color
    text-align: center
    padding: 30px

  &__inner
    max-width: 740px
    padding: 30px 0 60px

  form
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    transition: opacity 200ms ease

    &.is-loading
      pointer-events: none
      opacity: .4

  &__field
    max-width: 100%
    width: 100%
    padding-top: 15px

    &--half
      max-width: 49%
      +mq(tablet)
        max-width: 100%

    input,
    textarea
      display: block
      width: 100%
      -webkit-appearance: none
      margin-top: 5px
      background-color: $light-color
      border: 1px solid #e6e6e6
      border-radius: 6px
      font-family: inherit
      padding: 12px 10px
      line-height: 1
      +font-size(14px)

    textarea
      min-height: 140px
      line-height: 1.6
      resize: vertical

    label
      padding-left: 15px
      +font-size(14px)
      +mq(tablet)
        padding-left: 0
      
  &__footer
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding-top: 40px
    +mq(tablet)
      display: block
      padding-top: 10px

      button[type="submit"]
        margin: 15px auto 0

  &__checkboxes
    max-width: 460px

    .checkbox
      margin-top: 10px
      label
        display: inline-block
        +font-size(12px)

        &::before
          display: inline-block
          margin-top: .2em

        a
          color: $accent-color-3

  &__message
    padding: 12px 10px
    border-radius: 6px
    border: 1px solid transparent
    font-weight: $bold
    +font-size(12px)

    &--error
      color: $accent-color-2
      border-color: $accent-color-2
      background-color: transparentize($accent-color-2, .85)

    &--success
      color: $accent-color-3
      border-color: $accent-color-3
      background-color: transparentize($accent-color-3, .9)
