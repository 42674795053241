@mixin clearBoth
  &::after
    content: ''
    display: block
    clear: both

@mixin backgroundImage($path, $size: auto, $position: left top, $repeat: false)
  background-image: url($assetPathImg + $path)

  @if $repeat == false
    background-repeat: no-repeat

    background-position: $position
    background-size: $size

@mixin alignTextColumn
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center

@mixin borderImage($path, $slice, $style)
  border-image: url($assetPathImg + $path) $slice $style
  -webkit-border-image: url($assetPathImg + $path) $slice $style /* Safari 3.1-5 */
  -o-border-image: url($assetPathImg + $path) $slice $style /* Opera 11-12.1 */

@mixin borderRadius($size)
  -webkit-border-radius: $size
  -moz-border-radius: $size
  border-radius: $size

@function parseInt($n)
  @return $n / ($n * 0 + 1)

@mixin rem($property, $values)
  $px: ()
  $rem: ()

  @each $value in $values

    @if $value == 0 or $value == auto
      $px: append($px , $value)
      $rem: append($rem, $value)

    @else
      $unit: unit($value)
      $val: parseInt($value)

      @if $unit == "px"
        $px: append($px, $value)
        $rem: append($rem, ($val / 10 + rem))

      @if $unit == "rem"
        $px: append($px, ($val * 10 + px))
        $rem: append($rem, $value)

  @if $px == $rem
    #{$property}: $px
  @else
    #{$property}: $rem

@mixin font-size($value)
  +rem(font-size, $value)

@mixin lineHeight($value)
  +rem(line-height, $value)

@mixin square($size)
  width: $size
  height: $size

@mixin disc($size)
  +square($size)
  border-radius: 50%

@mixin imgContain($pourcent: 100%, $hasMinWidth: false)
  @if $hasMinWidth
    min-width: $pourcent
    min-height: $pourcent
    max-width: $pourcent
    max-height: $pourcent
    overflow: hidden

@mixin retina
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
    @content

@mixin mq($size)
  @if map-has-key($breakpoints, $size)
    $breakpoint: map-get($breakpoints, $size)
    @media #{$breakpoint}
      @content
  @else
    $breakpoint: $size
    @media #{$breakpoint}
      @content

@function z($layer)
  @if not map-has-key($z-indexes, $layer)
    @error 'There is no `#{$layer}` key in the $z-indexes map'

  @return map-get($z-indexes, $layer)

@mixin placeholder($color, $color-hover: lighten($color, 20%), $font-style: normal)
  &:-ms-input-placeholder
    color: $color
    font-style: $font-style
  &::-webkit-input-placeholder
    color: $color
    font-style: $font-style
  &::placeholder
    color: $color
    opacity: 1
    font-style: $font-style

  &:focus:-ms-input-placeholder
    color: $color-hover
  &:focus::-webkit-input-placeholder
    color: $color-hover
  &:focus::placeholder
    color: $color-hover
