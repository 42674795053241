.home-columns,
.home-columns-extranet
  margin-top: 170px
  background-color: $medium-color
  +mq(tablet)
    margin-top: 40px
    background-color: transparent

  &--small
    +mq(tablet)
      margin: 30px 0

    .home-columns__inner
      transform: none

    .home-columns__left
      transform: translateY(-90px)
      +mq(tablet)
        transform: none

        img
          margin-bottom: 0

    .home-columns__right
      +mq(tablet)
        margin-top: 0

      .content
        color: $dark-color
        padding: 20px 0

      .button
        margin: 0
        transform: none
        +mq(tablet)
          margin: 0 auto
  
  &__inner
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding-left: 80px
    transform: translateY(-90px)
    +mq(tablet)
      flex-direction: column
      padding-left: 0
      transform: none

  &__left
    max-width: 480px
    width: 100%
    color: $primary-color
    +mq(tablet)
      margin: 0 auto

      .section-title
        text-align: center

      .section-subtitle
        text-align: center
      
      .button
        margin: 20px auto 0

    img
      width: 100%
      margin: 15px 0

    .content
      padding-bottom: 20px
      +mq(tablet)
        padding: 0 20px

  &__right
    max-width: 460px
    width: 100%
    color: $light-color
    +mq(tablet)
      max-width: 480px
      margin: 40px auto 0

    &--pink
      background-color: $accent-color-2

    &--blue
      background-color: $accent-color-3

    .content
      padding: 20px 15px
      color: $light-color

    .button
      margin: 0 auto
      border: 2px solid $medium-color
      transform: translateY(50%)
      +mq(tablet)
        border: 0
        background-color: $primary-dark-color

  &__video
    display: flex
    justify-content: center
    align-items: center
    background-position: center
    background-size: cover
    height: 220px

    svg
      width: 75px
      height: 75px
      fill: $light-color

  &__header
    display: flex
    justify-content: space-between
    align-items: flex-end
    padding: 15px 15px 0

    img
      max-width: 60px