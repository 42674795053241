.content-page
  .section-title
    color: $medium-color
    background-color: $primary-color
    text-align: center
    padding: 30px

  &__content
    padding-top: 40px
    +mq(mobile)
      padding-top: 25px