.animated
    &--delay-01, &--delay-01 > *::after
        animation-delay: 0.1s !important

    &--delay-02, &--delay-02 > *::after
        animation-delay: 0.2s !important

    &--delay-03, &--delay-03 > *::after
        animation-delay: 0.3s !important

    &--delay-04, &--delay-04 > *::after
        animation-delay: 0.4s !important

    &--delay-05, &--delay-05 > *::after
        animation-delay: 0.5s !important

    &--delay-06, &--delay-06 > *::after
        animation-delay: 0.6s !important

    &--delay-07, &--delay-07 > *::after
        animation-delay: 0.7s !important

    &--delay-08, &--delay-08 > *::after
        animation-delay: 0.8s !important

    &--delay-09, &--delay-09 > *::after
        animation-delay: 0.9s !important

    &--delay-10, &--delay-10 > *::after
        animation-delay: 1s !important

    &--delay-11, &--delay-11 > *::after
        animation-delay: 1.1s !important

    &--delay-12, &--delay-12 > *::after
        animation-delay: 1.2s !important

    &--delay-14, &--delay-14 > *::after
        animation-delay: 1.4s !important

    &--delay-16, &--delay-16 > *::after
        animation-delay: 1.6s !important

    &--delay-18, &--delay-18 > *::after
        animation-delay: 1.8s !important

    &--delay-20, &--delay-20 > *::after
        animation-delay: 2.0s !important

    &--delay-22, &--delay-22 > *::after
        animation-delay: 2.2s !important

    &--delay-24, &--delay-24 > *::after
        animation-delay: 2.4s !important

    &--delay-26, &--delay-26 > *::after
        animation-delay: 2.6s !important

    &--delay-28, &--delay-28 > *::after
        animation-delay: 2.8s !important

    &--delay-30, &--delay-30 > *::after
        animation-delay: 3.0s !important

    &--delay-32, &--delay-32 > *::after
        animation-delay: 3.2s !important

    &--delay-34, &--delay-34 > *::after
        animation-delay: 3.4s !important

    &--delay-36, &--delay-36 > *::after
        animation-delay: 3.6s !important

    &.slideUp
        opacity: 0

        &.in
            @include animation(slideUp, 1s)

    &.fadeSlideLtoR
        opacity: 0
        &.in
            @include animation(fadeSlideLtoR, 1s)

    &.fadeSlideRtoL
        opacity: 0
        &.in
            @include animation(fadeSlideRtoL, 1s)

    &.focus
        filter: blur(5px)

        &.in
            @include animation(focus, 1s)

    &.fadeIn
        opacity: 0

        &.in
            @include animation(fadeIn, 1s)

    &.zoom
        transform: scale(0)

        &.in
            @include animation(zoom, 1s)
