html
  font-size: 62.5%
  height: 100%

html, body
  margin: 0
  padding: 0

*, *::before, *::after
  box-sizing: border-box

body
  min-height: 100%
  background: $light-color
  color: $dark-color
  font-family: $body-font
  line-height: 1.667
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility !important
  overflow-x: hidden
  +font-size(16px)

.no-scroll
  overflow: hidden

a
  color: inherit
  text-decoration: none
  &:hover
    text-decoration: underline

button,
input[type="button"],
input[type="submit"]
  margin: 0
  padding: 0
  background: none
  color: inherit
  cursor: pointer
  outline: none
  font: inherit
  border: none
  border-radius: 0
  -webkit-appearance: none

picture
  display: block

svg
  width: auto
  height: 100%

.Icon
  display: inline-block

#root
  min-height: 100vh
  opacity: 1 !important
  transition: opacity 600ms ease

  &.root--columns
    flex-direction: column

.page
  display: flex
  flex-direction: column
  min-height: 100vh
  height: 100%
  width: 100%

  &__content
    flex-grow: 1
    padding-top: 125px
    +mq(tablet)
      padding-top: 95px
    +mq(mobile)
      padding-top: 65px

.wrapper
  max-width: 1120px
  width: calc(100% - 20px)
  margin: 0 auto

  &--small
    max-width: 990px

.half-wrapper
  max-width: 560px
  width: 100%

  &--small
    max-width: 510px

.section-title
  text-transform: uppercase
  font-weight: $bold
  line-height: 1
  +font-size(22px)
  +mq(tablet)
    +font-size(20px)
  +mq(mobile)
    +font-size(18px)

.section-subtitle
  font-style: italic
  font-weight: $light
  +font-size(20px)
  +mq(tablet)
    +font-size(18px)
  +mq(mobile)
    +font-size(16px)

.dot
    &--blue:before
      background-color: $primary-color
      margin-bottom: 1px

    &--green:before
      background-color: $accent-color-1

    &:before
        content: ''
        height: 13px
        width: 13px
        display: inline-block
        margin-right: 16px

.content
  color: $dark-color
  +font-size(14px)

  u
    text-decoration: underline
    text-decoration-color: $primary-color

  em
    font-style: italic

  strong
    font-weight: $bold
    color: $primary-color

  p
    padding-top: 20px

  li
    padding-top: 10px
    list-style-type: disc
    list-style-position: inside

  img
    width: 100%

@keyframes spin
  from
    transform: rotate(0)
  from
    transform: rotate(-360deg)

@keyframes slide-up
  from
    opacity: 0
    transform: translateY(15%)
  to
    opacity: 1
    transform: none