.news-cards
  overflow: hidden
  +mq(tablet)
    background-color: #efefef
    padding-bottom: 40px

  .section-title
    padding: 40px 0
    text-align: center

  &__inner
    overflow: hidden

  &__item
    max-width: 360px
    width: 100%
    +mq(tablet)
      background-color: $light-color

    a:hover
      text-decoration: none

    &__thumb
      width: 100%
      height: 220px
      background-size: cover
      background-position: center

    &__title
      padding-top: 10px
      font-weight: $semi-bold
      +mq(tablet)
        padding: 20px 20px 0

    &__content
      padding-top: 5px
      +font-size(14px)
      +mq(tablet)
        padding: 0 20px 20px
    
    &__insert
      position: absolute
      top: 0
      right: 0
      background-color: $primary-color
      color: $light-color
      text-transform: uppercase
      font-weight: 600
      padding: 10px 27px
      +font-size(14px)

  &__nav
    display: flex
    justify-content: flex-end
    padding-top: 25px
    +mq(tablet)
      display: none

    &__item
      display: flex
      align-items: center
      justify-content: center
      width: 30px
      height: 30px
      margin-left: 10px
      background-color: $accent-color-1

      svg
        height: 16px
        width: 8px
        fill: $light-color

.news-extranet
    .news-cards__nav__item
      background-color: $primary-color