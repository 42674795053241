.cookies,
.flash
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  padding: 15px 0
  background-color: $primary-color
  color: $light-color
  z-index: z(cookies)
  +font-size(12px)

  &.is-visible
    display: block

  &__inner
    display: flex
    +mq(tablet)
      flex-direction: column

  &__buttons
    display: flex
    align-items: center
    flex-shrink: 0
    padding-left: 50px
    +mq(tablet)
      padding-left: 0
      padding-top: 15px

  &__button
    display: block
    padding: 5px 10px
    font-weight: bold
    text-transform: uppercase
    line-height: 1

    &--outline
      border: 1px solid $light-color
      border-radius: 25px

.cookies
  display: none

.flash
  top: 0
  bottom: auto
  padding-top: 8px
  padding-bottom: 8px

  &__inner
    align-items: center
    justify-content: space-between

  &__buttons
    cursor: pointer
    +font-size(22px)
    +mq(tablet)
      +font-size(18px)
    +mq(mobile)
      padding: 0
      +font-size(35px)

    label
      cursor: pointer

  &__input:checked
    + .flash
      display: none
